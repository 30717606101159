import React from 'react';
import '../css/Dashboard.css';
import DashBoardHeading from './DashBoardHeading';
import DashBoardTable from './DashBoardTable';
import InformationForm from './InformationForm';
import PDFGen from './PDFGen';

const DashBoard = ({ scores, balls, result, processPercent }) => {
  return (
    <div className="dashboard v-flex">
      <DashBoardHeading />
      <InformationForm userEmail={result.user_email} companyName={result.user_company} projectName={result.user_project_code} />
      <DashBoardTable scores={scores} balls={balls} result={result} processPercent={processPercent} />
      <PDFGen userEmail={result.user_email} scores={scores} result={result} processPercent={processPercent} />
    </div>
  );
}

export default DashBoard;
