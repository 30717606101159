import React, { Component } from 'react';

class PDFRuleThreeCompany extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.companyArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-company-3-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #3: Use Your Data As Currency Company Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Three</p>
            <p>Company</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">High quality big data assets</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-1"><p>No large data sets are within our control today</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-1"><p>A few large data sets that meet at least 4 quality criteria</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-1"><p>Many large data sets that meet at least 4 quality criteria</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-1"><p>A few large data sets that meet all 7 quality criteria</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-3-1"><p>Many large data sets that meet all 7 quality criteria</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Manageable data liabilities</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-2"><p>Have not even started to assess our exposure here</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-2"><p>Inventory of our data governance issues in place</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-2"><p>Just starting to act on our data governance obligations</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-2"><p>Compliant in most counties but work to do in a few</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-3-2"><p>No outstanding data liabilities as we are in full compliance</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Place value on data optionality</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-3"><p>Do no value future profit streams from data in any systematic way</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-3"><p>Treat second-order value of data as "upside" with no explicit value</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-3"><p>Willing to make big up-front investments to acquire new data assets</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-3"><p>Can calculate the future value of new data sets on a one-off project basis</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-3-3"><p>Sophisticated financial model in place to value second- and third-order effects</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Board customer segment focus</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-4"><p>We have no systematic approach to use digital user cases</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-4"><p>Just starting to classify use cases into digital customer segments</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-4"><p>Solely focused on the needs of internal digital customers today</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-4"><p>Delivering high-value solutions to at least three of the digital customer segments</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-3-4"><p>Delivering high-value digital solutions to each of the four customer segments</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">RBuilding data science team</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-5"><p>Do not yet have any data scientists on the payroll</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-5"><p>Have generalist data scientists today without any specialization</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-5"><p>Have data scientists focused within at least 1 of the algorithmic optimization areas</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-5"><p>Have data scientists focused within at least 3 of the algorithmic optimization areas</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-3-5"><p>Have data scientists focused within each of the 5 algorithmic optimization areas</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Mastering machine learning</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-6"><p>No platform or capability in place to embark on machine learning yet</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-6"><p>Heavily reliant on human data scientists with machine learning just getting started</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-6"><p>Data science and machine learning having an equal impact today</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-3-6"><p>Our algorithmic advantage is now delivered by machine learning</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-3-6"><p>Machine learning breakthroughs have made us the industry benchmark</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">15</p>
      </div>
    );
  }

}

export default PDFRuleThreeCompany;
