import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleTwoCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "company",
      rule: 2,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.company.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.company,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Two</p>
            <p>Company Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>Both Big I and Little I</p>
            <div className="rule-select" data-row="1" data-col="1">Innovation, what innovation?</div>
            <div className="rule-select" data-row="1" data-col="2">100% focused on Little I incremental improvements</div>
            <div className="rule-select" data-row="1" data-col="3">Last Big I idea did not work so we never tried again</div>
            <div className="rule-select" data-row="1" data-col="4">80% Little I and 20% Big I innovation portfolio today</div>
            <div className="rule-select" data-row="1" data-col="5">50%/50% perfect balance between Little I and Big I</div>
          </div>
          <div className="h-flex rule-block">
            <p>Innovation culture</p>
            <div className="rule-select" data-row="2" data-col="1">We are solely focused on running today's business</div>
            <div className="rule-select" data-row="2" data-col="2">A dedicated innovation team is in place - that's enough</div>
            <div className="rule-select" data-row="2" data-col="3">25%+ of our employees feels engaged in innovation</div>
            <div className="rule-select" data-row="2" data-col="4">50%+ of our employees feel engaged in innovation</div>
            <div className="rule-select" data-row="2" data-col="5">Every employee is empowered to deliver innovation</div>
          </div>
          <div className="h-flex rule-block">
            <p>Fast action on Little I</p>
            <div className="rule-select" data-row="3" data-col="1">Little I initiatives struggle for access to human and financial capital</div>
            <div className="rule-select" data-row="3" data-col="2">Little I initiatives are often late in delivering their promised results</div>
            <div className="rule-select" data-row="3" data-col="3">Well-honed process in place to expedite execution of Little I improvements</div>
            <div className="rule-select" data-row="3" data-col="4">Success across many Little I initiative has built deep bench of entrepreneurial talent</div>
            <div className="rule-select" data-row="3" data-col="5">Taking market and profit share through impact of Little I improvements</div>
          </div>
          <div className="h-flex rule-block">
            <p>Power of And</p>
            <div className="rule-select" data-row="4" data-col="1">We do not seem able to balance competing priorities well</div>
            <div className="rule-select" data-row="4" data-col="2">Central Innovation Group is in place but has no power to control resourcing</div>
            <div className="rule-select" data-row="4" data-col="3">Central Innovation Group manages portfolio effectively across initiatives</div>
            <div className="rule-select" data-row="4" data-col="4">Sophisticated risk vs. return metrics used to pursue competing initiatives in parallel</div>
            <div className="rule-select" data-row="4" data-col="5">We operate at the "efficient frontier" of innovation portfolio management</div>
          </div>
          <div className="h-flex rule-block">
            <p>Venture Investment Board</p>
            <div className="rule-select" data-row="5" data-col="1">No Venture Investment Board in place today</div>
            <div className="rule-select" data-row="5" data-col="2">VIB is in place but plays a coordinating role, not a decision making one</div>
            <div className="rule-select" data-row="5" data-col="3">VIB prioritizes ideas but relies on BUs for headcount and budget</div>
            <div className="rule-select" data-row="5" data-col="4">VIB has internal and external experts plus its own headcount and budget authority</div>
            <div className="rule-select" data-row="5" data-col="5">VIB is empowered to compete with our core business as required to grow</div>
          </div>
          <div className="h-flex rule-block">
            <p>Big I Relay Race</p>
            <div className="rule-select" data-row="6" data-col="1">Big I ideas follow the same process as any other project in our company</div>
            <div className="rule-select" data-row="6" data-col="2">1+ Big I initiatives are allowed to run in "stealth mode" away from core business</div>
            <div className="rule-select" data-row="6" data-col="3">Big I initiatives follow a well-defined, test-and-learn methodology</div>
            <div className="rule-select" data-row="6" data-col="4">Big I ideas have special rules to allow for rapid execution and risk taking</div>
            <div className="rule-select" data-row="6" data-col="5">Big I has CEO-level prerogative to move at Silicon Valley velocity</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleTwoCompany;
