import React from 'react';
import amazonButton from '../assets/amazonbutton.png';
import '../css/Subscribe.css';

const Subscribe = () => {
  return (
    <div className="subscribe">
      <div className="h-flex">
        <a href="https://www.amazon.com/Goliaths-Revenge-Established-Companies-Disruptors/dp/1119541875/ref=sr
            _1_1?ie=UTF8&qid=1533319632&sr=8-1&keywords=goliath%27s+revenge" title=""><img src={amazonButton} alt="amazon" /></a>
      </div>
    </div>
  );
}

export default Subscribe;
