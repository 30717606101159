import auth0 from 'auth0-js';
import history from '../history';

export default class Auth {
  accessToken;
  idToken;
  expiresAt;
  userInfo;

  auth0 = new auth0.WebAuth({
    domain: 'goliath.auth0.com',
    clientID: '0By6D0qlGuQ2L80Tw1thhN3ubLDHUFly',
    redirectUri: `${window.location.origin}/loading`,
    responseType: 'token id_token',
    scope: 'openid email profile'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {

        this.setSession(authResult);
      } else if (err) {
        history.replace('/selfassessment')
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  getUserProfile() {
    // Get user info
    const accessToken = this.accessToken
    let userEmail;

    if (accessToken) {
      this.auth0.client.userInfo(accessToken, (err, profile) => {
        userEmail = this.userEmail;
      });
    }
    return userEmail;
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    this.userEmail = authResult.idTokenPayload.email
    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    history.replace('/selfassessment')

  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult);
       } else if (err) {
         this.logout();
         console.log(err);
         alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
       }
    });
  }

  logout() {

    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');

    // fetch(`https://goliath.auth0.com/v2/logout?returnTo=${window.location.origin}/selfassessment`);

    window.location.href = `https://goliath.auth0.com/v2/logout?returnTo=${window.location.origin}/selfassessment&client_id=${this.auth0.baseOptions.clientID}`

  }

  isAuthenticated() {
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }

}
