import React, { Component } from 'react';

class PDFRuleFiveCompany extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.companyArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-company-5-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #5: Value Talent Over Technology Company Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Five</p>
            <p>Company</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Honor institutional knowledge</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-1"><p>Long-term employees are working to undermine the digital transformation</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-1"><p>Seasoned employees and digital newcomers beginning to trust each other</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-1"><p>Some teams collaborating well across veteran staff and new digital hires</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-1"><p>Veteran staff and new digital hires investing substantial time to learn from each other</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-5-1"><p>Veteran staff and outside digital hires working seamlessly to invent the futures</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Go beyond "3D" digital roles</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-2"><p>Facing a major shortage of design, development, data science talent</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-2"><p>Most required design, development, data science talent in place and productive</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-2"><p>Significant talent in place for at least two of the six "beyond 3D" digital roles</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-2"><p>Significant talent in place for at least four of the six "beyond 3D" digital roles</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-5-2"><p>Significant talent in place for five or all six of the "beyond 3D" digital roles</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Commit to pre-emptive skill development</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-3"><p>Company is reactive in developing digital skills</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-3"><p>Dependent on recruiting as main source of digital skills</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-3"><p>Strong mobility programs developing skills internally</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-3"><p>Fully leveraging partners and freelancers to fill skill gaps</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-5-3"><p>Skill grafting delivering "one plus one equals five" talent</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Value Venture General Managers</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-4"><p>Venture GM role not well understood or valued</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-4"><p>Aware that Venture GM pool is limiting growth</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-4"><p>Modest pool of Venture GM's recycled across opportunities</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-4"><p>Venture GM pool viewed as competitive advantage</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-5-4"><p>Venture GM's driving multiple breakout growth plays</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Optimize the AI-Human Balance</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-5"><p>Human staff actively sabotaging AI rollouts to protect jobs</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-5"><p>Human staff view AI with suspicion and worried for their jobs</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-5"><p>Human staff in the midst of resetting roles due to rapidly improving AI</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-5"><p>AI and Humans fully adjusted to the "new normal" - working well</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-5-5"><p>Humans growing impact by proactively offloading basic work to AI</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Improve your digital dexterity</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-6"><p>None of the 12 drivers of digital dexterity in place</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-6"><p>At least 3 of the 12 drivers of digital dexterity in place</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-6"><p>At least 6 of the 12 drivers of digital dexterity in place</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-5-6"><p>At least 9 of the 12 drivers of digital dexterity in place</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-5-6"><p>All 12 drivers of digital dexterity in place and driving results</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">19</p>
      </div>
    );
  }

}

export default PDFRuleFiveCompany;
