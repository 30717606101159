import React, { Component } from 'react';
import '../css/Authorized.css';
import { Router, Route } from 'react-router-dom';
import history from '../history';
import DashBoard from './DashBoard';
import RuleOne from '../rules/RuleOne';
import RuleTwo from '../rules/RuleTwo';
import RuleThree from '../rules/RuleThree';
import RuleFour from '../rules/RuleFour';
import RuleFive from '../rules/RuleFive';
import RuleSix from '../rules/RuleSix';
import Loading from './Loading';
import ReactGA from 'react-ga';
import baseURL from '../services/api_url';

import ball0 from '../assets/harvey/0.jpg';
import ball1 from '../assets/harvey/1.jpg';
import ball2 from '../assets/harvey/2.jpg';
import ball3 from '../assets/harvey/3.jpg';
import ball4 from '../assets/harvey/4.jpg';
import ball5 from '../assets/harvey/5.jpg';
import ball6 from '../assets/harvey/6.jpg';

ReactGA.initialize('UA-134561461-1')
history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
});

class Authorized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null
    };
    this.getResult = this.getResult.bind(this);
    this.updateProps = this.updateProps.bind(this);
    this.processPercent = this.processPercent.bind(this);
  }

  componentDidMount() {
    this.getResult();
  }

  processPercent(score) {
    let totalScore = score.split('').reduce((acc, cur) => {
      return acc = (acc + parseInt(cur));
    }, 0)
    return Math.ceil((totalScore * 20) / 6);
  }

  processScore(value, selected) {
    let score = 0;
    for (let i = 0; i < value.length; i ++) {
      const ele = parseInt(value[i]);
      const eleSelected = selected[i];
      if (ele > 0 && eleSelected === "t") {
        score ++;
      }
    }
    return score.toString();
  }

  processResultRecords(result) {
    const types = ['company', 'career'];
    const rules = [1, 2, 3, 4, 5, 6];
    const balls = [ball0, ball1, ball2, ball3, ball4, ball5, ball6];
    let scoresObj = {};
    let ballsObj = {};

    for (let i = 0; i < types.length; i ++) {
      let type = types[i];
      for (let j = 0; j < rules.length; j ++) {
        let rule = rules[j]

        let resultKey = `${type}_${rule}`
        let resultKeySelected = `${type}_${rule}_selected`;

        let score = this.processScore(result[resultKey], result[resultKeySelected])

        scoresObj = {...scoresObj, [resultKey]: score};

        ballsObj = {...ballsObj, [resultKey]: balls[score]};
      }
    }
    ReactGA.pageview(window.location.pathname)
    this.setState({
      result: result,
      scores: scoresObj,
      balls: ballsObj,
      scoreLoaded: true
    })
  }

  updateProps(key, value) {
    this.setState({
      result: {...this.state.result, [key]: value}
    })
    this.processResultRecords(this.state.result);
  }

  getResult() {

    fetch(`${baseURL}/api/result/?id=${this.props.auth.userEmail}`)
      .then(res => res.json())
      .then((res) => {
        this.processResultRecords(res.result);
      })
  }

  render() {
    return (
      <div>
        <div className="v-flex">
          {
            this.state.scoreLoaded ?
            (
              <Router history={history}>
                <div>
                  <Route
                    exact
                    path="/selfassessment"
                    render={props => <DashBoard {...props}
                      scores={this.state.scores}
                      balls={this.state.balls}
                      result={this.state.result}
                      processPercent={this.processPercent}
                    />}
                  />
                  <Route
                    path="/selfassessment/ruleone"
                    render={props => <RuleOne {...props}
                      company={this.state.result.company_1}
                      career={this.state.result.career_1}
                      companySelected={this.state.result.company_1_selected}
                      careerSelected={this.state.result.career_1_selected
                      }
                      email={this.props.auth.userEmail}
                      getResult={this.getResult}
                      updateProps={this.updateProps}
                      companyScore={this.state.scores.company_1}
                      careerScore={this.state.scores.career_1}
                      companyBall={this.state.balls.company_1}
                      careerBall={this.state.balls.career_1}
                      processPercent={this.processPercent}
                    />}
                  />

                  <Route
                    path="/selfassessment/ruletwo"
                    render={props => <RuleTwo {...props}
                      company={this.state.result.company_2}
                      career={this.state.result.career_2}
                      companySelected={this.state.result.company_2_selected}
                      careerSelected={this.state.result.career_2_selected
                      }
                      email={this.props.auth.userEmail}
                      getResult={this.getResult}
                      updateProps={this.updateProps}
                      companyScore={this.state.scores.company_2}
                      careerScore={this.state.scores.career_2}
                      companyBall={this.state.balls.company_2}
                      careerBall={this.state.balls.career_2}
                      processPercent={this.processPercent}
                    />}
                  />

                  <Route
                    path="/selfassessment/rulethree"
                    render={props => <RuleThree {...props}
                      company={this.state.result.company_3}
                      career={this.state.result.career_3}
                      companySelected={this.state.result.company_3_selected}
                      careerSelected={this.state.result.career_3_selected
                      }
                      email={this.props.auth.userEmail}
                      getResult={this.getResult}
                      updateSelection={this.updateSelection}
                      updateProps={this.updateProps}
                      companyScore={this.state.scores.company_3}
                      careerScore={this.state.scores.career_3}
                      companyBall={this.state.balls.company_3}
                      careerBall={this.state.balls.career_3}
                      processPercent={this.processPercent}
                    />}
                  />

                  <Route
                    path="/selfassessment/rulefour"
                    render={props => <RuleFour {...props}
                      company={this.state.result.company_4}
                      career={this.state.result.career_4}
                      companySelected={this.state.result.company_4_selected}
                      careerSelected={this.state.result.career_4_selected
                      }
                      email={this.props.auth.userEmail}
                      getResult={this.getResult}
                      updateProps={this.updateProps}
                      companyScore={this.state.scores.company_4}
                      careerScore={this.state.scores.career_4}
                      companyBall={this.state.balls.company_4}
                      careerBall={this.state.balls.career_4}
                      processPercent={this.processPercent}
                    />}
                  />

                  <Route
                    path="/selfassessment/rulefive"
                    render={props => <RuleFive {...props}
                      company={this.state.result.company_5}
                      career={this.state.result.career_5}
                      companySelected={this.state.result.company_5_selected}
                      careerSelected={this.state.result.career_5_selected
                      }
                      email={this.props.auth.userEmail}
                      getResult={this.getResult}
                      updateProps={this.updateProps}
                      companyScore={this.state.scores.company_5}
                      careerScore={this.state.scores.career_5}
                      companyBall={this.state.balls.company_5}
                      careerBall={this.state.balls.career_5}
                      processPercent={this.processPercent}
                    />}
                  />

                  <Route
                    path="/selfassessment/rulesix"
                    render={props => <RuleSix {...props}
                      company={this.state.result.company_6}
                      career={this.state.result.career_6}
                      companySelected={this.state.result.company_6_selected}
                      careerSelected={this.state.result.career_6_selected
                      }
                      email={this.props.auth.userEmail}
                      getResult={this.getResult}
                      updateProps={this.updateProps}
                      companyScore={this.state.scores.company_6}
                      careerScore={this.state.scores.career_6}
                      companyBall={this.state.balls.company_6}
                      careerBall={this.state.balls.career_6}
                      processPercent={this.processPercent}
                    />}
                  />
                </div>
              </Router>
            ) : (
              <Loading />
            )
          }

        </div>
      </div>
    )
  }
}

export default Authorized;
