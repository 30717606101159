import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import Home from './pages/Home';
import Book from './pages/Book';
import AuthorOne from './pages/AuthorOne';
import AuthorTwo from './pages/AuthorTwo';
import PrivacyPolicy from './components/PrivacyPolicy'
import history from './history';
import ReactGA from 'react-ga';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import './App.css';

ReactGA.initialize('UA-134561461-1')
history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
});

library.add(fab, faEnvelope);

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <div className="App">

        <div className="body-container">
          <Router history={history}>
            <div>
              <Route
                exact
                path="/"
                component={Home}
              />
              <Route
                path="/book"
                component={Book}
              />
              <Route
                path="/toddhewlin"
                component={AuthorOne}
              />
              <Route
                path="/scottsnyder"
                component={AuthorTwo}
              />
              <Route
                path="/privacypolicy"
                component={PrivacyPolicy}
              />
            </div>
          </Router>
        </div>

      </div>
    );
  }
}

export default App;
