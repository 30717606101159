import React from 'react';
import { Router, Link } from 'react-router-dom';
import history from '../history';

const RuleTwoProgress = ({ companyScore, careerScore, companyBall, careerBall, company, career, processPercent }) => {

  let companyPercent = processPercent(company);
  let careerPercent = processPercent(career);

  return (
    <div className="dash-rule-list-right v-flex">
      <Router history={history}>
        <div className="h-flex">
          <Link to="/selfassessment/ruletwo/company">
            <p className="dash-rule-lable">Rule #2</p>
          </Link>

          <div className="dash-rule-text v-flex">
            <p>Pursue Big I and</p>
            <p>Little I Innovation</p>
          </div>
        </div>
      </Router>

      <div className="dash-nav h-flex">
        <div>
          <p>
            Company
          </p>
          <div className="dash-nav-block h-flex">
            <div>
              <p>Progress</p>
              <img src={companyBall} alt="company pie" />
            </div>
            <div>
              <p>Avg Score</p>
              <p className="percent">{companyPercent}%</p>
            </div>
          </div>
        </div>

        <div>
          <p>
            Career
          </p>
          <div className="dash-nav-block h-flex">
            <div>
              <p>Progress</p>
              <img src={careerBall} alt="career pie" />
            </div>
            <div>
              <p>Avg Score</p>
              <p className="percent">{careerPercent}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RuleTwoProgress;
