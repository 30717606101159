import React, { Component } from 'react';

class PDFRuleFourCareer extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.careerArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-career-4-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #4: Accelerate Via Innovation Networks Career Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Four</p>
            <p>Career</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Beyond "We Know Everything"</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-1"><p>Cannot understand why things need to keep changing so often</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-1"><p>Understand that big changes are afoot but don't see my role in making them happen</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-1"><p>I am naturally curious and a committed lifelong learner</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-1"><p>I am wel-versed on digital transformation and actively leading change in my area</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-4-1"><p>Am well known for evangelizing new ideas across our company</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Multiple innovation channels</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-2"><p>Have not been involved in co-innovation projects with external partners</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-2"><p>Have worked on co-innovation projects across 2 of the 8 innovation channels</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-2"><p>Have worked on co-innovation projects across 4 of the 8 innovation channels</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-2"><p>Have worked on co-innovation projects across 6 of the 8 innovation channels</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-4-2"><p>Have worked on co-innovation projects across all 8 of the innovation channels</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Right gives and gets</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-3"><p>Not involved in adding innovation partners to our company's network</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-3"><p>Provide support to the teams that are activating new innovation partners</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-3"><p>Deeply involved in negotiating new partnerships with external innovators</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-3"><p>Lead our company's efforts within one of the open innovation channels</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-4-3"><p>Lead our company's efforts across multiple open innovation channels</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Easy to innovate with</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-4"><p>No previous experience in making our open innovation approach better</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-4"><p>Have identified areas where our company can make open innovation easier</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-4"><p>Active team member on multiple projects to streamline our open innovation process</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-4"><p>Lead our company's effort to design the friction out of open innovation</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-4-4"><p>Am recognized across the industry as a thought leader on open innovation</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Robust innovation sandbox</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-5"><p>Not working as part of our open innovation sandbox teams</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-5"><p>Member of one of our key sandbox teams - APIs, data sets, or technology tools</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-5"><p>Lead one of our key sandbox teams - APIs, data sets, or technology tools</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-5"><p>Drive our ongoing efforts to make our open innovation sandbox better</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-4-5"><p>Lead our efforts to engage external innovators with our data and tools</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Corporate development toolkit</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-6"><p>1+ No experience yet executing Corporate Development deals</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-6"><p>Have worked on deal teams across 1 of our deal structures</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-6"><p>Have worked on deal teams across multiple deal structures</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4-6"><p>Have led deals within 1 of our Corporate Development structures</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-4-6"><p>Have led deals across multiple Corporate Development structures</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">18</p>
      </div>
    );
  }
}

export default PDFRuleFourCareer;
