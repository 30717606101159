import React from 'react';
import '../css/Quotes.css';
import { Carousel } from 'react-responsive-carousel';

const Quotes = () => {
  return (
    <div className="quote-container">

      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showIndicators={false}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
      >

        {/* Quote 1 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “Digitalization is causing all companies to rethink the value they deliver to customers and the skills they develop in their employees. Goliath’s Revenge provides actionable best practices and detailed case examples for both.”</i>
              </p>
              <br />
              <p>
                Toshiaki Higashihara, CEO, Hitachi
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“Goliath's Revenge succeeds where most business books fall short by delivering the practical steps needed to transform your company to its new growth model for the digital future. Definitely worth a read.”</i>
              </p>
              <br />
              <p>
                Dave Yost, former CEO AmerisourceBergen and Board Director for Bank of America, Marsh & McLennan, Johnson Controls
              </p>
            </div>
          </div>
        </div>

        {/* Quote 2 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “Digital disruption is now the norm, with the consumer in the driver seat. Goliath’s Revenge can help leaders realise their digital ambitions.”</i>
              </p>
              <br />
              <p>
                Jan Zijderveld, CEO Avon
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“Goliath’s Revenge goes beyond the stale doom and gloom stories of digital disruption to offer incumbents a playbook to craft their own journey and the confidence to invest in it.”</i>
              </p>
              <br />
              <p>
                Julie Ask, Vice President and Principal Analyst Forrester
              </p>
            </div>
          </div>
        </div>

        {/* Quote 3 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “Will your business be disrupted?  Definitely. Do the disruptors have to be a small band of hackers in a garage?  Definitely not. This book is full of practical tips for big companies to get a leg up on scrappy startups.”</i>
              </p>
              <br />
              <p>
                Adam Grant, New York Times Best-Selling Author of ORIGINALS, GIVE AND TAKE, and OPTION B with Sheryl Sandberg
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“An invaluable roadmap for shaping your career with specific helpful recommendations around the new skills and experiences required to prosper in the digital age.”</i>
              </p>
              <br />
              <p>
                Mehrdad Baghai, New York Times and Wall Street Journal Best-Selling Author and Industry Professor, University of Technology Sydney
              </p>
            </div>
          </div>
        </div>

        {/* Quote 4 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “In the dizzying race toward digital transformation, companies face the daunting task of setting the right tone, vision, and path forward. Every global leader should read this with a keen eye.”</i>
              </p>
              <br />
              <p>
                Shane Tedjarati, President & CEO, Global High Growth Regions, Honeywell
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“Digital transformation requires companies to be leaders, not spectators. Goliath’s Revenge shows how large enterprises are uniquely equipped to deliver step-change digital outcomes, and to do so at scale.”</i>
              </p>
              <br />
              <p>
                Mike Lawrie, CEO DXC Technology
              </p>
            </div>
          </div>
        </div>

        {/* Quote 5 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “A must read for how companies can successfully develop a digital program to defend their core franchises and grow with new digital services.”</i>
              </p>
              <br />
              <p>
                Bill Ruh, CEO, GE Digital
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“There has never been a more important time for boards to to be aware of digital disruption. Goliath's Revenge lays out the key elements that boards and leadership teams should focus on.”</i>
              </p>
              <br />
              <p>
                Peter Gleason, CEO, National Association of Corporate Directors
              </p>
            </div>
          </div>
        </div>

        {/* Quote 6 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “Traditional boundaries in retail are breaking down. Hewlin and Snyder show how to accelerate digital innovation around experiences that meets the needs of today's consumer.”</i>
              </p>
              <br />
              <p>
                Chris Gheysens, CEO Wawa
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“Goliath's Revenge provides the roadmap that established companies need as they put their data to work and run the Silicon Valley playbook for themselves.”</i>
              </p>
              <br />
              <p>
                Doug Merritt, President and CEO, Splunk
              </p>
            </div>
          </div>
        </div>

        {/* Quote 7 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “Goliath's Revenge lays out the courageous journey every leading company must take to stay on top. Hewlin and Snyder provide the rules for reinventing your company and yourself.”</i>
              </p>
              <br />
              <p>
                Krishnan Rajagopalan, CEO Heidrick & Struggles
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“Digital is changing how healthcare is delivered and empowering patients to be more involved in their care. Goliath's Revenge lays out how to shape this transformation.”</i>
              </p>
              <br />
              <p>
                Madeline Bell, CEO Children’s Hospital of Philadelphia
              </p>
            </div>
          </div>
        </div>

        {/* Quote 8 */}
        <div className="carousel-slide">
          <div className="quote-block">
            <div className="quote-block-top">
              <p><i>
                “Hewlin and Snyder have provided a great guide for any entrenched company to win in today's data rich world while staying ahead of disruption.”</i>
              </p>
              <br />
              <p>
                Mark Vergnano, CEO, Chemours
              </p>
            </div>
            <br />
            <div className="quote-block-bottom">
              <p>
                <i>“We are well into the digital revolution in healthcare. The rules in Goliaths Revenge will separate the disruptors from the disrupted.”</i>
              </p>
              <br />
              <p>
                Lynnette Cooke, CEO, Kantar Health
              </p>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default Quotes;

