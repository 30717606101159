import React, { Component } from 'react';


class PDFRuleFiveCareer extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.careerArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-career-5-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #5: Value Talent Over Technology Career Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Five</p>
            <p>Career</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Honor institutional knowledge</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-1"><p>Sometimes viewed as divisive and political even if you are not really like that</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-1"><p>Open to working with people from different organizations if required</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-1"><p>Proven ability to work equally well with veteran staff and new digital hires alike</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-1"><p>Viewed by peers as a great connector between disparate organizations</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-5-1"><p>In-demand to lead teams that leverage long-term staff and new digital hires</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Go beyond "3D" digital roles</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-2"><p>No experience yet in the "3D" roles let alone the six new digital roles</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-2"><p>Experience in at least one of the design, development, data science roles</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-2"><p>Have completed job rotations in at least one of the six new digital roles</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-2"><p>Have completed job rotations in at least two of the six new digital roles</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-5-2"><p>Significant experience in at least three of the six new "beyond 3D" digital roles</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Commit to pre-emptive skill development</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-3"><p>Unclear on your professional skill gaps relative to future digital demand</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-3"><p>Understand your skill gaps but have not done anything to address them</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-3"><p>Have made lateral career move to build your digital skill set</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-3"><p>Have taken online courses on personal time to develop digital skills</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-5-3"><p>Already worked in paired jobs for rapid skill development</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Value Venture General Managers</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-4"><p>Have no general manager skills to speak of</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-4"><p>Have general manager skills but only in a mature business</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-4"><p>Have led a successful innovation ventures</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-4"><p>Strong results leading many innovation teams</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-5-4"><p>Peers seek your mentorship to build Venture GM skills</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Optimize the AI-Human Balance</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-5"><p>Act defensively when presented with AI projects and initiatives</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-5"><p>View AI suspiciously as it regards your future career</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-5"><p>Have led AI projects that delivered their promised results</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-5"><p>Expert at getting AI and Humans working well</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-5-5"><p>Viewed as AI innovator that is savvy about human impact</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Improve your digital dexterity</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-6"><p>Do not yet demonstrate any drivers of digital dexterity</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-6"><p>Demonstrate at least 3 drivers of digital dexterity</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-6"><p>Demonstrate at least 6 drivers of digital dexterity</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5-6"><p>Demonstrate at least 9 drivers of digital dexterity</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-5-6"><p></p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">20</p>
      </div>
    );
  }
}

export default PDFRuleFiveCareer;
