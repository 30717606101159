import React, { Component } from 'react';

class PDFRuleTwoCompany extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.companyArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-company-2-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur-1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #2: Pursue Big I And Little I Company Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Two</p>
            <p>Company</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Both Big I and Little I</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-1"><p>Innovation, what innovation?</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-1"><p>100% focused on Little I incremental improvements</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-1"><p>Last Big I idea did not work so we never tried again</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-1"><p>80% Little I and 20% Big I innovation portfolio today</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-2-1"><p>50%/50% perfect balance between Little I and Big I</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Innovation culture</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-2"><p>We are solely focused on running today's business</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-2"><p>A dedicated innovation team is in place - that's enough</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-2"><p>25%+ of our employees feels engaged in innovation</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-2"><p>50%+ of our employees feel engaged in innovation</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-2-2"><p>Every employee is empowered to deliver innovation</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Fast action on Little I</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-3"><p>Little I initiatives struggle for access to human and financial capital</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-3"><p>Little I initiatives are often late in delivering their promised results</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-3"><p>Well-honed process in place to expedite execution of Little I improvements</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-3"><p>Success across many Little I initiative has built deep bench of entrepreneurial talent</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-2-3"><p>Taking market and profit share through impact of Little I improvements</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Power of And</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-4"><p>We do not seem able to balance competing priorities well</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-4"><p>Central Innovation Group is in place but has no power to control resourcing</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-4"><p>Central Innovation Group manages portfolio effectively across initiatives</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-4"><p>Sophisticated risk vs. return metrics used to pursue competing initiatives in parallel</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-2-4"><p>We operate at the "efficient frontier" of innovation portfolio management</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Venture Investment Board</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-5"><p>No Venture Investment Board in place today</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-5"><p>VIB is in place but plays a coordinating role, not a decision making one</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-5"><p>VIB prioritizes ideas but relies on BUs for headcount and budget</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-5"><p>VIB has internal and external experts plus its own headcount and budget authority</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-2-5"><p>VIB is empowered to compete with our core business as required to grow</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Big I Relay Race</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-6"><p>Big I ideas follow the same process as any other project in our company</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-6"><p>1+ Big I initiatives are allowed to run in "stealth mode" away from core business</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-6"><p>Big I initiatives follow a well-defined, test-and-learn methodology</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-2-6"><p>Big I ideas have special rules to allow for rapid execution and risk taking</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-2-6"><p>Big I has CEO-level prerogative to move at Silicon Valley velocity</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">13</p>
      </div>
    );
  }

}

export default PDFRuleTwoCompany;
