import React, { Component } from 'react';
import baseURL from '../services/api_url';

class InformationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      companyName: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      projectName: this.props.projectName,
      companyName: this.props.companyName
    })
  }

  handleChange(e) {
    // console.log(this.state)
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    fetch(`${baseURL}/api/resultupdate?companyName=${this.state.companyName}&projectName=${this.state.projectName}&userEmail=${this.props.userEmail}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify()
    })
  }

  render() {
    return (
      <div className="form-container v-flex">
        <form className="form-block v-flex">
          <p>Company Name:</p>
          <input type="text" name="companyName" placeholder="Optional" onChange={this.handleChange} value={this.state.companyName} />
          <p>Project Name:</p>
          <input type="text" name="projectName" placeholder="Optional" onChange={this.handleChange} value={this.state.projectName} />
        </form>
        <button onClick={this.handleSubmit}><p>Save</p></button>
      </div>
    )
  }

}

export default InformationForm;
