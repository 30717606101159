import React from 'react';
import Authorized from '../components/Authorized';
import NotAuthorized from '../components/NotAuthorized';
import SignUpForm from '../components/SignUpForm';
import '../css/SelfAssessment.css';
import CookieConsentPop from '../components/CookieConsentPop';

const SelfAssessment = ({ auth }) => {
  return(
    <div className="selfassessment">
      <SignUpForm
        auth={auth}
      />
      {
        localStorage.getItem('isLoggedIn') === 'true' && auth.isAuthenticated() ? (
          <div>
            <Authorized
              auth={auth}
            />

          </div>
        ) : (
          <div>
            <NotAuthorized
              auth={auth}
            />
          </div>
        )
      }
      <CookieConsentPop />
    </div>
  )
}

export default SelfAssessment;
