import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from '../history';
import RuleFourCompany from './RuleFourCompany';
import RuleFourCareer from './RuleFourCareer';
import RuleTableHeading from '../components/RuleTableHeading';
import RuleFourProgress from './RuleFourProgress';

const RuleFour = ({ company, career, companySelected, careerSelected, email, getResult, updateProps, companyScore, careerScore, companyBall, careerBall, processPercent }) => {
  return (
    <div>
      <RuleTableHeading />
      <div className="center">
        <RuleFourProgress
          companyScore={companyScore}
          careerScore={careerScore}
          companyBall={companyBall}
          careerBall={careerBall}
          company={company}
          career={career}
          processPercent={processPercent}
        />
      </div>

      <Router history={history}>
        <div className="v-flex rule-container">
          <Route
            path="/selfassessment/rulefour/company"
            render={props => <RuleFourCompany {...props}
              company={company}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={companySelected}
            />}
          />
          <Route
            path="/selfassessment/rulefour/career"
            render={props => <RuleFourCareer {...props}
              career={career}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={careerSelected}
            />}
          />
        </div>
      </Router>
    </div>
  );
}

export default RuleFour;
