import React, { Component } from 'react';

class PDFCareer extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    for (let i = 0; i < 6; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-career-${i + 1}`)
      const curTotal = Math.floor((this.props.careerArr[i].split('').reduce((acc, cur) => {
        return acc + parseInt(cur)
      }, 0)) / 6)
      for (let i = 0; i < curTotal; i++) {
        htmlColl[i].classList.add('pdf-table-filled-orange')
      }
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Career Readiness Summary</h2>
        <div className="h-flex pdf-cate">
          <div className="empty border-to-right">
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Rule #1 Deliver Step-Change Customer Outcomes</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1"></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-1"></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Rule #2 Pursue Big I and Little I Innovation</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2"></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-2"></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Rule #3 Use Your Data as Currency</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3"></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-3"></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Rule #4 Accelerate Through Innovation Networks</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-4"></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-4"></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Rule #5 Value Talent Over Technology</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-5"></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-5"></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Rule #6 Reframe Your Purpose</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6"></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6"></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-6"></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">4</p>
      </div>
    );
  }
}

export default PDFCareer;
