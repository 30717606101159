import React, { Component } from 'react';

class PDFRuleOneCompany extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.companyArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-company-1-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #1 Step-Change Customer Outcomes Company Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule One</p>
            <p>Company</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">10X customer value</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-1"><p>Outcome value not yet validated with customers</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-1"><p>Traditional customer value model with a 3-5 year payback</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-1"><p>Differentiated value on two of: higher revenue, lower cost, reduced risk</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-1"><p>Differentiated value on three of: higher revenue, lower cost, reduced risk</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-1-1"><p>Customer-validated ROI model showing 10X cash-on-cash returns</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Incumbent's advantage</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-2"><p>Outcome does not average any existing crown jewels</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-2"><p>Outcome builds on crown jewels in 1-2 categories</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-2"><p>Outcome builds on crown jewels in 3-4 categories</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-2"><p>Outcome builds on crown jewels in 5-6 categories</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-1-2"><p>Outcome builds on crown jewels in all 7 categories</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Buyer persona outcomes</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-3"><p>Overall step-change customer outcome not yet broken down by buyer persona</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-3"><p>Outcomes developed for only a subset of the 4 step-specific buyer personas</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-3"><p>All 4 step-specific customer outcomes developed but not yet validated</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-3"><p>Step-specific outcomes validated with &#60; 10 customers in each of 4 personas</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-1-3"><p>Step-specific outcomes validated with 10+ customers in each of 4 personas</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Whole offer clarity</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-4"><p>Whole offers specified by step buy without detailed capability definitions</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-4"><p>Capability definitions in place for 2+ whole offer on each of steps 1 and 2</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-4"><p>Capability definitions in place for 2+ whole offer on each of the 4 steps</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-4"><p>Build vs. Partner decisions made for capabilities in step 1 and 2 while offers</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-1-4"><p>Build vs. Partner sourcing clear for every whole offer on every step</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Whole offer ecosystem</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-5"><p>Whole offers capabilities partners not yet sourced and signed</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-5"><p>At least 1 validated partner sourced and signed for Step 1 and 2 whole offers</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-5"><p>Multiple validated partners sourced and signed for Step 1 and 2 whole offers</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-5"><p>At least 1 validated partner sourced, signed and trained for all whole offers</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-1-5"><p>Multiple validated partners sourced, signed, and trained for all whole offers</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Time to market</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-6"><p>1+ whole offer(s) can be in market within 12 months on each of steps 1 and 2</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-6"><p>1+ whole offer(s) can be in market within 6 months on each of steps 1 and 2</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-6"><p>1+ whole offer(s) already in market on stairway step 1</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-1-6"><p>1+ whole offer(s) already in market on each of steps 1 and 2</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-1-6"><p>1+ whole offer(s) already in market on each of steps 1, 2, 3</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">11</p>
      </div>
    );
  }

}

export default PDFRuleOneCompany;
