import React, { Component } from 'react';

class SignUpForm extends Component {

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentDidMount() {
    const { renewSession } = this.props.auth;

    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession();
    }
  }

  render() {

    const { isAuthenticated } = this.props.auth;

    return (
      <div className="sign-up-container v-flex">
        {
          !isAuthenticated() && (
            <div>
              <h3 className="title">Complete Your Self-Assessment</h3>
              <button
                className="logbtn dash-rule-lable"
                onClick={this.login.bind(this)}
              >
                Log In
              </button>
            </div>
          )
        }
        { isAuthenticated() && (
          <div>
            <button
              className="logbtn dash-rule-lable"
              onClick={this.logout.bind(this)}
            >
              Log Out
            </button>
          </div>
          )
        }
      </div>
    )
  }

}

export default SignUpForm;
