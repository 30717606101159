import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleThreeCareer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "career",
      rule: 3,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.career.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.career,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Three</p>
            <p>Career Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>Have quality big data assets</p>
            <div className="rule-select" data-row="1" data-col="1">Have not been part of my company's push into big data and analytics</div>
            <div className="rule-select" data-row="1" data-col="2">Appreciate the value of high-quality data but do not have skills in this area</div>
            <div className="rule-select" data-row="1" data-col="3">Invested personal time to learn data science basics but on-the-job experience is limited</div>
            <div className="rule-select" data-row="1" data-col="4">Deep experience in making data valuable for data science and machine learning</div>
            <div className="rule-select" data-row="1" data-col="5">Go-to leader for assessing the quality and business potential of new data sets</div>
          </div>
          <div className="h-flex rule-block">
            <p>Manageable data liabilities</p>
            <div className="rule-select" data-row="2" data-col="1">Not involved with commitments around data rights and obligations</div>
            <div className="rule-select" data-row="2" data-col="2">Have negotiated commercial contracts that include explicit data rights</div>
            <div className="rule-select" data-row="2" data-col="3">Up-to-speed on the trend toward more complex data governance laws</div>
            <div className="rule-select" data-row="2" data-col="4">Substantial expertise in implementing data privacy and security safeguards</div>
            <div className="rule-select" data-row="2" data-col="5">Recognized expert on reginal data sovereignty and governance</div>
          </div>
          <div className="h-flex rule-block">
            <p>Place value on data optionality</p>
            <div className="rule-select" data-row="3" data-col="1">Do not see any intrinsic value in data - it is just a means to an end</div>
            <div className="rule-select" data-row="3" data-col="2">I am not able to place a value on the direct, near-term impact of data</div>
            <div className="rule-select" data-row="3" data-col="3">Willing to include future data value as we make prioritization decisions</div>
            <div className="rule-select" data-row="3" data-col="4">Attribute explicit value to second-order effects of acquiring new data assets</div>
            <div className="rule-select" data-row="3" data-col="5">I built our company's model for valuing second- and third-order effects</div>
          </div>
          <div className="h-flex rule-block">
            <p>Board customer segment focus</p>
            <div className="rule-select" data-row="4" data-col="1">No material experience yet delivering on digital use cases</div>
            <div className="rule-select" data-row="4" data-col="2">Direct experience in use cases within at least 1 digital customer segment</div>
            <div className="rule-select" data-row="4" data-col="3">Direct experience in use cases within at least 2 digital customer segments</div>
            <div className="rule-select" data-row="4" data-col="4">Direct experience in use cases within at least 3 digital customer segments</div>
            <div className="rule-select" data-row="4" data-col="5">Direct experience in use cases for all 4 of the digital customer segments</div>
          </div>
          <div className="h-flex rule-block">
            <p>Building data science team</p>
            <div className="rule-select" data-row="5" data-col="1">Few data science skills and minimal big data analytics experience yet</div>
            <div className="rule-select" data-row="5" data-col="2">Built data science skills through online and company training programs</div>
            <div className="rule-select" data-row="5" data-col="3">Demonstrated success across multiple data science projects</div>
            <div className="rule-select" data-row="5" data-col="4">Go-to leader within multiple stages of the analytics value chain from data and insights</div>
            <div className="rule-select" data-row="5" data-col="5">Expert in all facets of turning big data into actionable, high-value business insights</div>
          </div>
          <div className="h-flex rule-block">
            <p>Mastering machine learning</p>
            <div className="rule-select" data-row="6" data-col="1">No experience or skill yet in machine learning tools and projects</div>
            <div className="rule-select" data-row="6" data-col="2">Building my skills as part of my first machine learning project</div>
            <div className="rule-select" data-row="6" data-col="3">Experienced with multiple successful machine learning efforts</div>
            <div className="rule-select" data-row="6" data-col="4">Viewed as the go-to expert to deliver continuous machine learning</div>
            <div className="rule-select" data-row="6" data-col="5">Teach machine learning at our local college in my spare time</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleThreeCareer;
