import React, { Component } from 'react';

class PDFRuleOneCareer extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.careerArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-career-1-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #1: Step-Change Customer Outcomes Career Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule One</p>
            <p>Career</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">10X customer value</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-1"><p>Current role and past experience not related to customer value delivery</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-1"><p>Indirect role in the company's customer value delivery process</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-1"><p>Recognized experience delivering substantial customer value</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-1"><p>A past role was an essential part of 10X customer value delivery</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-1-1"><p>Current role is central to delivering the validated 10X customer outcome</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Incumbent's advantage</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-2"><p>Not involved with any of the crown jewels that support the step-change outcome</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-2"><p>On the team related to a crown jewel that supports the step-change outcome</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-2"><p>A major contributor to a crown jewel that supports the step-change outcome</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-2"><p>The go-to person for a crown jewel that supports the step-change outcome</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-1-2"><p>Played a leadership role on teams related to 2+ crown jewels over time</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Buyer persona outcomes</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-3"><p>Role does not entail any professional interactions with customers</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-3"><p>Support teams that interact with customers but do not do so personally</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-3"><p>Could independently qualify a customer as one of the 4 buyers personas</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-3"><p>Have professional relationships with customers to enable co-innovation efforts</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-1-3"><p>Have professional relationships with customers in each of the 4 buyer personas</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Whole offer clarity</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-4"><p>Can add minimal value to the creation of whole offer specifications</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-4"><p>Up-to-date with market research on customer needs and current competition</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-4"><p>Recognized expert on the unmet needs of customers in each buyer persona</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-4"><p>Go-to leader who can specify capability requirements for multiple whole offers</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-1-4"><p>Recognized expert for Build vs. Partner decisions within multiple whole offers</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Whole offer ecosystem</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-5"><p>Can add minimal value to whole offer partner selection and contracting</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-5"><p>Up-to-date with the capabilities of potential whole offer partners</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-5"><p>Past experience validating and contracting with whole offer partners</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-5"><p>Recognized expert within an important category of whole offer partners</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-1-5"><p>Recognized expert across multiple categories of whole offer partners</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Time to market</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-6"><p>Minimal training and experience in past time-to-market initiatives</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-6"><p>Trained in Agile methodology and co-innovation initiatives</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-6"><p>In-demand team member for urgent projects and strategic initiatives</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-1-6"><p>Experienced team leader for urgent projects and strategic initiatives</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-1-6"><p>Recognized company wide for excellence in urgent strategic initiatives</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">12</p>
      </div>
    );
  }
}

export default PDFRuleOneCareer;
