import React, { Component } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import PDFCover from '../PDFPages/PDFCover';
import PDFTable from '../PDFPages/PDFTable';
import PDFCompany from '../PDFPages/PDFCompany';
import PDFCareer from '../PDFPages/PDFCareer';
import PDFRuleOne from '../PDFPages/PDFRuleOne';
import PDFRuleOneCompany from '../PDFPages/PDFRuleOneCompany';
import PDFRuleOneCareer from '../PDFPages/PDFRuleOneCareer';
import PDFRuleTwo from '../PDFPages/PDFRuleTwo';
import PDFRuleTwoCompany from '../PDFPages/PDFRuleTwoCompany';
import PDFRuleTwoCareer from '../PDFPages/PDFRuleTwoCareer';
import PDFRuleThree from '../PDFPages/PDFRuleThree';
import PDFRuleThreeCompany from '../PDFPages/PDFRuleThreeCompany';
import PDFRuleThreeCareer from '../PDFPages/PDFRuleThreeCareer';
import PDFRuleFour from '../PDFPages/PDFRuleFour';
import PDFRuleFourCompany from '../PDFPages/PDFRuleFourCompany';
import PDFRuleFourCareer from '../PDFPages/PDFRuleFourCareer';
import PDFRuleFive from '../PDFPages/PDFRuleFive';
import PDFRuleFiveCompany from '../PDFPages/PDFRuleFiveCompany';
import PDFRuleFiveCareer from '../PDFPages/PDFRuleFiveCareer';
import PDFRuleSix from '../PDFPages/PDFRuleSix';
import PDFRuleSixCompany from '../PDFPages/PDFRuleSixCompany';
import PDFRuleSixCareer from '../PDFPages/PDFRuleSixCareer';

import '../css/PDFGen.css';

class PDFGen extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount

  pdfExportComponent;

  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
  }

  render() {

    const { career_1, career_2, career_3, career_4, career_5, career_6 } = this.props.result;
    const careerArr = [career_1, career_2, career_3, career_4, career_5, career_6];
    const { company_1, company_2, company_3, company_4, company_5, company_6 } = this.props.result;
    const companyArr = [company_1, company_2, company_3, company_4, company_5, company_6];

    return (
      <div className="pdf-main-container v-flex">
        <button className="logbtn pdf-btn" onClick={this.exportPDFWithComponent}>
          Generate PDF Report
        </button>
        <div className="pdf-container v-flex" >

          <PDFExport ref={(component) => this.pdfExportComponent = component} paperSize="A4">
            <PDFCover userEmail={this.props.userEmail} />
            <PDFTable />
            <PDFCompany companyArr={companyArr} />
            <PDFCareer careerArr={careerArr} />
            <PDFRuleOne
              companyRuleArr={companyArr[0]}
              careerRuleArr={careerArr[0]}
            />
            <PDFRuleTwo
              companyRuleArr={companyArr[1]}
              careerRuleArr={careerArr[1]}
            />
            <PDFRuleThree
              companyRuleArr={companyArr[2]}
              careerRuleArr={careerArr[2]}
            />
            <PDFRuleFour
              companyRuleArr={companyArr[3]}
              careerRuleArr={careerArr[3]}
            />
            <PDFRuleFive
              companyRuleArr={companyArr[4]}
              careerRuleArr={careerArr[4]}
            />
            <PDFRuleSix
              companyRuleArr={companyArr[5]}
              careerRuleArr={careerArr[5]}
            />
            <PDFRuleOneCompany companyArr={companyArr[0]} />
            <PDFRuleOneCareer careerArr={careerArr[0]} />
            <PDFRuleTwoCompany companyArr={companyArr[1]} />
            <PDFRuleTwoCareer careerArr={careerArr[1]} />
            <PDFRuleThreeCompany companyArr={companyArr[2]} />
            <PDFRuleThreeCareer careerArr={careerArr[2]} />
            <PDFRuleFourCompany companyArr={companyArr[3]} />
            <PDFRuleFourCareer careerArr={careerArr[3]} />
            <PDFRuleFiveCompany companyArr={companyArr[4]} />
            <PDFRuleFiveCareer careerArr={careerArr[4]} />
            <PDFRuleSixCompany companyArr={companyArr[5]} />
            <PDFRuleSixCareer careerArr={careerArr[5]} />
          </PDFExport>
        </div >
      </div>

    )
  }
}

export default PDFGen;
