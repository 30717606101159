import React from 'react';
import '../css/AuthorOne.css';
import img from '../assets/todd.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';

const AuthorOne = () => {
  return (
    <div className="authorone-container">

      {/* top block */}
      <div className="authorone-block">
        <h3 className="title">
          Todd Hewlin
        </h3>
        <div className="authorone-top h-flex">
          <div className="authorone-top-left v-flex">

            <img src={img} alt="todd hewlin" />
            <div className="social-icon h-flex">
              <a href="https://www.linkedin.com/in/toddhewlin/" title=""><FontAwesomeIcon
                icon={['fab', 'linkedin']}
                size='3x'
                color='#007bb6'
              /></a>
              <a href="https://twitter.com/toddhewlin?lang=en" title=""><FontAwesomeIcon
                icon={['fab', 'twitter']}
                size='3x'
                color='#38A1F3'
              /></a>
              <a href="mailto:info@tcg-advisors.com"><FontAwesomeIcon
                icon='envelope'
                size='3x'
                color='#606D89'
              /></a>
              </div>
            <Button variant="outlined" color="primary" className="buttons-left">
              <a href="mailto:info@tcg-advisors.com">
                Speaking Inquiries for Todd
              </a>
            </Button>
          </div>
          <div className="authorone-top-right v-flex">
            <p>Todd Hewlin is a Managing Director at TCG Advisors, the leading growth consulting strategy firm in Silicon Valley, and a noted expert on digitization and business model innovation. He is co-author of the books Goliath’s Revenge, Consumption Economics and B4B, as well as articles in Harvard Business Review, Wired, McKinsey Quarterly, and Knowledge@Wharton.</p>
            <br />
            <p>Todd is regularly found in the boardroom of market leaders advising them on how to grow in a digital world. His clients include Microsoft, Salesforce, Cisco, Hitachi, GE Digital, Veritas, Brambles, Telstra, and Macquarie Capital. Prior to TCG Advisors, Todd ran the $1.5 billion product business at enterprise mobility leader Symbol Technologies. Earlier in his career, he was a Managing Director at Internet Capital Group and a Partner at McKinsey & Company.</p>
            <br />
            <p>Todd is in demand for industry keynotes including events with Microsoft, Cisco, Salesforce, GE Digital, Autodesk, IRI, and Progress Software. See below for his thoughts on Digital Disruption, Business Model Reinvention, and Breakthrough Innovation.</p>
          </div>
        </div>
      </div>

      <div className="video-block h-flex">
        <div>
          <iframe width="770" height="435" src="https://www.youtube.com/embed/G1BspUFYg9A?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title="Digital Disruption"></iframe>
        </div>
        <div>
        <h3 className="title">Digital Disruption</h3>
          <br />
          <p>Data science, machine learning, and robotics are reshaping the global economy.
          Technology companies are now fighting for a share of every industry’s profit pool. The
          groundbreaking research behind <i>Goliath’s Revenge</i> has shown that six rules are
          separating the leaders from the also-rans in this “winner takes most” competition.</p>
        </div>
      </div>

      <div class="video-block row-2 h-flex">
        <div>
          <h3 className="title">Business Model Reinvention</h3>
          <br/>
          <p>Business model innovation is trumping product innovation. Future leaders are
          shifting from B2B to B4B with solutions that increase revenues, lower costs, and reduce
          risk. They are leaving their legacy business models behind to focus all of their energy on
          marketing, selling, and delivering customer outcomes.</p>
        </div>
        <div>
          <iframe width="770" height="435" src="https://www.youtube.com/embed/1ZJa8S8-Lxw?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title="business model reinvention"></iframe>
        </div>
      </div>

      <div class="video-block h-flex">
        <div>
          <iframe width="780" height="435" src="https://www.youtube.com/embed/syeV_bl1xsE?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title="breakthrough innovation"></iframe>
        </div>
        <div>
          <h3 className="title">Breakthrough Innovation</h3>
          <br/>
          <p>Innovation is the fuel that powers your growth strategy. However, few companies are
          achieving their expected Return on Innovation in spite of heavy investment in R&D. We
          dispel the five myths of innovation to help put your company on the path to long-term
          profitable growth.</p>
        </div>
      </div>

    </div>

  );
}

export default AuthorOne;
