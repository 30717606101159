import React, { Component } from 'react';

class PDFRuleTwoCareer extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.careerArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-career-2-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #2: Pursue Big I And Little I Career Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Two</p>
            <p>Career</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Both Big I and Little I</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-1"><p>Have not been part of any innovation initiative</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-1"><p>Was on a continuous improvement team a long time ago</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-1"><p>Recognized as a valued Little I initiative team member</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-1"><p>Have been a key member of both Little I and Big I teams over time</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-2-1"><p>Have led multiple Little I and Big I initiatives to achieve their goals</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Innovation culture</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-2"><p>If we have an idea generation program, I am not familiar with it</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-2"><p>I know the email alias to send suggestions to but have never done so</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-2"><p>Am a regular contributor of improvement and new business ideas</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-2"><p>Have received executive recognition as source of successful innovative ideas</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-2-2"><p>Have been asked to lead part of our company wide innovation program</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Fast action on Little I</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-3"><p>I need to focus on delivering the core commitments of my current role</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-3"><p>I am willing to put in extra effort to be part of rapid improvement teams</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-3"><p>I am the "go to" person in my group for teams that need to get things done</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-3"><p>I have a network across the company that can help push through innovations</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-2-3"><p>I am the benchmark for how to execute innovation with limited resources</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Power of And</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-4"><p>I focus on one thing at a time and often lose track of other priorities</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-4"><p>I am working hard on my time management skills to be able to do more in parallel</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-4"><p>I can handle two initiatives in parallel but adding a third one is too much</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-4"><p>I am highly productive acting on multiple competing priorities</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-2-4"><p>Have a high tolerance for ambiguity; willing to act in the fact of limited information</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Venture Investment Board</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-5"><p>I did not realize that we had a Venture Investment Board</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-5"><p>Was part of a team that made an unsuccessful proposal to our VIB</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-5"><p>Was part of a team that gained "GO" approval from our VIB</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-5"><p>Had led multiple successful teams that gained "GO" approval from our VIB</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-2-5"><p>Have been asked to be a board-observer on our VIB to represent my group</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Big I Relay Race</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-6"><p>Have never expressed interest in being part of a Big I initiative team</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-6"><p>Am trained in our Big I methodology but have not yet been on a Big I team</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-6"><p>Have been a core member of at least one successful Big I team</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-2-6"><p>Have been a team leader on at least one successful Big I team</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-2-6"><p>Have acted as a faculty member within our company's Big I training program</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">14</p>
      </div>
    );
  }
}

export default PDFRuleTwoCareer;
