import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from '../history';
import RuleOneCompany from './RuleOneCompany';
import RuleOneCareer from './RuleOneCareer';
import RuleTableHeading from '../components/RuleTableHeading';
import RuleOneProgress from './RuleOneProgress';

const RuleOne = ({ company, career, companySelected, careerSelected,  email, getResult, updateProps, companyScore, careerScore, companyBall, careerBall, processPercent }) => {
  return (
    <div>
      <RuleTableHeading />
      <div className="center">
        <RuleOneProgress
          companyScore={companyScore}
          careerScore={careerScore}
          companyBall={companyBall}
          careerBall={careerBall}
          company={company}
          career={career}
          processPercent={processPercent}
        />
      </div>
      <Router history={history}>
        <div className="v-flex rule-container">
          <Route
            path="/selfassessment/ruleone/company"
            render={props => <RuleOneCompany {...props}
              company={company}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={companySelected}
            />}
          />
          <Route
            path="/selfassessment/ruleone/career"
            render={props => <RuleOneCareer {...props}
              career={career}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={careerSelected}
            />}
          />
        </div>
      </Router>
    </div>
  );
}

export default RuleOne;
