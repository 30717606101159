import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleSixCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "company",
      rule: 6,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.company.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.company,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes

      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Six</p>
            <p>Company Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>Raise your sights</p>
            <div className="rule-select" data-row="1" data-col="1">We don't believe in all that mission / vision stuff here</div>
            <div className="rule-select" data-row="1" data-col="2">Mission / vision too much about us; not enough about the world</div>
            <div className="rule-select" data-row="1" data-col="3">Vision is clear but we haven't internalized it into our mission</div>
            <div className="rule-select" data-row="1" data-col="4">Mission is right but vision may not be right for the long-term</div>
            <div className="rule-select" data-row="1" data-col="5">Mission / vision so compelling that everyone can recite them</div>
          </div>
          <div className="h-flex rule-block">
            <p>Answer the "Five Whys"</p>
            <div className="rule-select" data-row="2" data-col="1">We struggle to even answer the superficial first "why"</div>
            <div className="rule-select" data-row="2" data-col="2">Aligned at least 2 "whys" deep on why we do what we do</div>
            <div className="rule-select" data-row="2" data-col="3">Aligned at least 3 "whys" deep on why we do what we do</div>
            <div className="rule-select" data-row="2" data-col="4">Aligned at least 4 "whys" deep on why we do what we do</div>
            <div className="rule-select" data-row="2" data-col="5">Aligned at least 5 "whys" deep on why we do what we do</div>
          </div>
          <div className="h-flex rule-block">
            <p>Embrace smart cannibalization</p>
            <div className="rule-select" data-row="3" data-col="1">We are on a path to be the Kodak of our industry</div>
            <div className="rule-select" data-row="3" data-col="2">Only executing "speed two" model in adjacent markets; not our own</div>
            <div className="rule-select" data-row="3" data-col="3">Speed two model has permission for smart cannibalization but just launched</div>
            <div className="rule-select" data-row="3" data-col="4">Two-speed model in place but "haves and have nots" issues are still being worked on</div>
            <div className="rule-select" data-row="3" data-col="5">Two-speed organizational model in place and delivering well</div>
          </div>
          <div className="h-flex rule-block">
            <p>Engage the next generation</p>
            <div className="rule-select" data-row="4" data-col="1">Do not think Digital Natives would enjoy working for our company</div>
            <div className="rule-select" data-row="4" data-col="2">Beginning to get how Millennial and Gen Z employees are different</div>
            <div className="rule-select" data-row="4" data-col="3">We support employees who want to make a difference outside of work</div>
            <div className="rule-select" data-row="4" data-col="4">We've added either social or environmental goals to our financial ones</div>
            <div className="rule-select" data-row="4" data-col="5">We live and breath our triple bottom line every day</div>
          </div>
          <div className="h-flex rule-block">
            <p>Align top-down</p>
            <div className="rule-select" data-row="5" data-col="1">Every group has its own mission and vision so no alignment at company level</div>
            <div className="rule-select" data-row="5" data-col="2">Alignment is superficial today; not translated into day-to-day actions</div>
            <div className="rule-select" data-row="5" data-col="3">Board and senior leaders aligned but has not reached the staff doing real work</div>
            <div className="rule-select" data-row="5" data-col="4">Middle management to front line alignment in place but senior leaders are still TBD</div>
            <div className="rule-select" data-row="5" data-col="5">Broad and deep alignment from Board to front-line to partners in place</div>
          </div>
          <div className="h-flex rule-block">
            <p>Lead by example</p>
            <div className="rule-select" data-row="6" data-col="1">Our leaders seem near-sighted; solely focused on delivering near-term profits</div>
            <div className="rule-select" data-row="6" data-col="2">Our leaders might have strong personal purposes but I don't know what they are</div>
            <div className="rule-select" data-row="6" data-col="3">Some leaders have strong personal purposes but others not so much</div>
            <div className="rule-select" data-row="6" data-col="4">Our leaders have strong personal purposes but may not align to company</div>
            <div className="rule-select" data-row="6" data-col="5">Leaders and genuinely authentic with company-aligned personal purposes</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleSixCompany;
