import React from 'react';
import Landing from '../components/Landing';
import Subscribe from '../components/Subscribe';
import Authors from '../components/Authors';
import '../css/Home.css';
import slingShot from '../assets/slingshot.png';

const Home = () => {
  return (
    <div className="home">
      <Landing />
      <Subscribe />
      <Authors />
      <div className="six-rule-start">
        <img src={slingShot} alt="slingshot" />
        <h3 className="title">The "Six New Rules" of Goliath's Revenge</h3>
        <p>
          Established companies that are turning the tables on digital disruptors are following six new rules. If you have the book and want to check the readiness of your company and career for this digital future, click <span className="text-link"><a href="/selfassessment">here</a></span> to start your free self-assessment.
        </p>
      </div>
    </div>
  );
}

export default Home;
