import React from 'react';
import AuthorOne from '../assets/todd.png';
import AuthorTwo from '../assets/scott.png';
import '../css/Authors.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';

const Authors = () => {
  return (
    <div className="authors">
      <h3 className="title">
        Meet the Authors
      </h3>
      <div className="author-container h-flex">
        <div className="author-left v-flex">
          <img src={AuthorOne} alt="todd hewlin" />
          <h3 className="title">
            <a href="/toddhewlin">Todd Hewlin</a>
          </h3>
          <div className="social-icon h-flex">
            <a href="https://www.linkedin.com/in/toddhewlin/" title=""><FontAwesomeIcon
              icon={['fab', 'linkedin']}
              size='3x'
              color='#007bb6'
            /></a>
            <a href="https://twitter.com/toddhewlin?lang=en" title=""><FontAwesomeIcon
              icon={['fab', 'twitter']}
              size='3x'
              color='#38A1F3'
            /></a>
            <a href="mailto:info@tcg-advisors.com"><FontAwesomeIcon
              icon='envelope'
              size='3x'
              color='#606D89'
            /></a>

          </div>
          <p>
            <span className="text-link"><a href="/toddhewlin">Todd</a></span> is co-founder of TCG Advisors and a leading expert on digitization and business model innovation. He is co-author of Goliath’s Revenge, Consumption Economics and B4B, as well as articles in Harvard Business Review, Wired, McKinsey Quarterly, and Knowledge@Wharton.
            Todd is regularly found in the boardroom of market leaders advising them on how to grow in a digital world. His clients include Microsoft, Salesforce, Cisco, Hitachi, GE Digital, Veritas, Brambles, Telstra, and Macquarie Capital.
          </p>
          <Button variant="outlined" color="primary" className="buttons-left">
            <a href="mailto:info@tcg-advisors.com">
              Speaking Inquiries for Todd
            </a>
          </Button>
        </div>
        <div className="author-right v-flex">
          <img src={AuthorTwo} alt="scott snyder" />
          <h3 className="title">
            <a href="/scottsnyder">Scott Snyder</a>
          </h3>
          <div className="social-icon h-flex">
            <a href="https://www.linkedin.com/in/scottsnyder5g/" title=""><FontAwesomeIcon
              icon={['fab', 'linkedin']}
              size='3x'
              color='#007bb6'
            /></a>
            <a href="https://twitter.com/snyder4g" title=""><FontAwesomeIcon
              icon={['fab', 'twitter']}
              size='3x'
              color='#38A1F3'
            /></a>
            <a href="mailto:snyder4g@gmail.com"><FontAwesomeIcon
              icon='envelope'
              size='3x'
              color='#606D89'
            /></a>
          </div>
          <p>
            <span className="text-link"><a href="/scottsnyder">Scott</a></span> is a recognized thought leader in digital transformation and innovation having held leadership roles at GE and Lockheed Martin, launched three new technology ventures including Mobiquity, led technology and innovation for venture capital firm, Safeguard, and  Digital and Innovation at Heidrick & Struggles. He is author of The New World of Wireless: How to Compete in the 4G Revolution and has lectured at top universities on emerging technologies, digital strategy, and innovation including Wharton, MIT, Babson, Duke, and INSEAD.
          </p>
          <Button variant="outlined" color="primary" className="buttons-left">
            <a href="mailto:snyder4g@gmail.com">
              Speaking Inquiries for Scott
            </a>
          </Button>

        </div>
      </div>

    </div>
  );
}

export default Authors;
