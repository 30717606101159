import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleFiveCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "company",
      rule: 5,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.company.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.company,
      selected: this.props.selected
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
    .then(() => {
      this.props.getResult();
    })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Five</p>
            <p>Company Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p className="">Honor institutional knowledge</p>
            <div className="rule-select" data-row="1" data-col="1">Long-term employees are working to undermine the digital transformation</div>
            <div className="rule-select" data-row="1" data-col="2">Seasoned employees and digital newcomers beginning to trust each other</div>
            <div className="rule-select" data-row="1" data-col="3">Some teams collaborating well across veteran staff and new digital hires</div>
            <div className="rule-select" data-row="1" data-col="4">Veteran staff and new digital hires investing substantial time to learn from each other</div>
            <div className="rule-select" data-row="1" data-col="5">Veteran staff and outside digital hires working seamlessly to invent the futures</div>
          </div>
          <div className="h-flex rule-block">
            <p>Go beyond "3D" digital roles</p>
            <div className="rule-select" data-row="2" data-col="1">Facing a major shortage of design, development, data science talent</div>
            <div className="rule-select" data-row="2" data-col="2">Most required design, development, data science talent in place and productive</div>
            <div className="rule-select" data-row="2" data-col="3">Significant talent in place for at least two of the six "beyond 3D" digital roles</div>
            <div className="rule-select" data-row="2" data-col="4">Significant talent in place for at least four of the six "beyond 3D" digital roles</div>
            <div className="rule-select" data-row="2" data-col="5">Significant talent in place for five or all six of the "beyond 3D" digital roles</div>
          </div>
          <div className="h-flex rule-block">
            <p>Commit to pre-emptive skill development</p>
            <div className="rule-select" data-row="3" data-col="1">Company is reactive in developing digital skills</div>
            <div className="rule-select" data-row="3" data-col="2">Dependent on recruiting as main source of digital skills</div>
            <div className="rule-select" data-row="3" data-col="3">Strong mobility programs developing skills internally</div>
            <div className="rule-select" data-row="3" data-col="4">Fully leveraging partners and freelancers to fill skill gaps</div>
            <div className="rule-select" data-row="3" data-col="5">Skill grafting delivering "one plus one equals five" talent</div>
          </div>
          <div className="h-flex rule-block">
            <p>Value Venture General Managers</p>
            <div className="rule-select" data-row="4" data-col="1">Venture GM role not well understood or valued</div>
            <div className="rule-select" data-row="4" data-col="2">Aware that Venture GM pool is limiting growth</div>
            <div className="rule-select" data-row="4" data-col="3">Modest pool of Venture GM's recycled across opportunities</div>
            <div className="rule-select" data-row="4" data-col="4">Venture GM pool viewed as competitive advantage</div>
            <div className="rule-select" data-row="4" data-col="5">Venture GM's driving multiple breakout growth plays</div>
          </div>
          <div className="h-flex rule-block">
            <p>Optimize the AI-Human Balance</p>
            <div className="rule-select" data-row="5" data-col="1">Human staff actively sabotaging AI rollouts to protect jobs</div>
            <div className="rule-select" data-row="5" data-col="2">Human staff view AI with suspicion and worried for their jobs</div>
            <div className="rule-select" data-row="5" data-col="3">Human staff in the midst of resetting roles due to rapidly improving AI</div>
            <div className="rule-select" data-row="5" data-col="4">AI and Humans fully adjusted to the "new normal" - working well</div>
            <div className="rule-select" data-row="5" data-col="5">Humans growing impact by proactively offloading basic work to AI</div>
          </div>
          <div className="h-flex rule-block blue-border">
            <p>Improve your digital dexterity</p>
            <div className="rule-select" data-row="6" data-col="1">None of the 12 drivers of digital dexterity in place</div>
            <div className="rule-select" data-row="6" data-col="2">At least 3 of the 12 drivers of digital dexterity in place</div>
            <div className="rule-select" data-row="6" data-col="3">At least 6 of the 12 drivers of digital dexterity in place</div>
            <div className="rule-select" data-row="6" data-col="4">At least 9 of the 12 drivers of digital dexterity in place</div>
            <div className="rule-select" data-row="6" data-col="5">All 12 drivers of digital dexterity in place and driving results</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleFiveCompany;
