import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleThreeCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "company",
      rule: 3,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.company.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.company,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Three</p>
            <p>Company Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>High quality big data assets</p>
            <div className="rule-select" data-row="1" data-col="1">No large data sets are within our control today</div>
            <div className="rule-select" data-row="1" data-col="2">A few large data sets that meet at least 4 quality criteria</div>
            <div className="rule-select" data-row="1" data-col="3">Many large data sets that meet at least 4 quality criteria</div>
            <div className="rule-select" data-row="1" data-col="4">A few large data sets that meet all 7 quality criteria</div>
            <div className="rule-select" data-row="1" data-col="5">Many large data sets that meet all 7 quality criteria</div>
          </div>
          <div className="h-flex rule-block">
            <p>Manageable data liabilities</p>
            <div className="rule-select" data-row="2" data-col="1">Have not even started to assess our exposure here</div>
            <div className="rule-select" data-row="2" data-col="2">Inventory of our data governance issues in place</div>
            <div className="rule-select" data-row="2" data-col="3">Just starting to act on our data governance obligations</div>
            <div className="rule-select" data-row="2" data-col="4">Compliant in most counties but work to do in a few</div>
            <div className="rule-select" data-row="2" data-col="5">No outstanding data liabilities as we are in full compliance</div>
          </div>
          <div className="h-flex rule-block">
            <p>Place value on data optionality</p>
            <div className="rule-select" data-row="3" data-col="1">Do no value future profit streams from data in any systematic way</div>
            <div className="rule-select" data-row="3" data-col="2">Treat second-order value of data as "upside" with no explicit value</div>
            <div className="rule-select" data-row="3" data-col="3">Willing to make big up-front investments to acquire new data assets</div>
            <div className="rule-select" data-row="3" data-col="4">Can calculate the future value of new data sets on a one-off project basis</div>
            <div className="rule-select" data-row="3" data-col="5">Sophisticated financial model in place to value second- and third-order effects</div>
          </div>
          <div className="h-flex rule-block">
            <p>Board customer segment focus</p>
            <div className="rule-select" data-row="4" data-col="1">We have no systematic approach to use digital user cases</div>
            <div className="rule-select" data-row="4" data-col="2">Just starting to classify use cases into digital customer segments</div>
            <div className="rule-select" data-row="4" data-col="3">Solely focused on the needs of internal digital customers today</div>
            <div className="rule-select" data-row="4" data-col="4">Delivering high-value solutions to at least three of the digital customer segments</div>
            <div className="rule-select" data-row="4" data-col="5">Delivering high-value digital solutions to each of the four customer segments</div>
          </div>
          <div className="h-flex rule-block">
            <p>Building data science team</p>
            <div className="rule-select" data-row="5" data-col="1">Do not yet have any data scientists on the payroll</div>
            <div className="rule-select" data-row="5" data-col="2">Have generalist data scientists today without any specialization</div>
            <div className="rule-select" data-row="5" data-col="3">Have data scientists focused within at least 1 of the algorithmic optimization areas</div>
            <div className="rule-select" data-row="5" data-col="4">Have data scientists focused within at least 3 of the algorithmic optimization areas</div>
            <div className="rule-select" data-row="5" data-col="5">Have data scientists focused within each of the 5 algorithmic optimization areas</div>
          </div>
          <div className="h-flex rule-block">
            <p>Mastering machine learning</p>
            <div className="rule-select" data-row="6" data-col="1">No platform or capability in place to embark on machine learning yet</div>
            <div className="rule-select" data-row="6" data-col="2">Heavily reliant on human data scientists with machine learning just getting started</div>
            <div className="rule-select" data-row="6" data-col="3">Data science and machine learning having an equal impact today</div>
            <div className="rule-select" data-row="6" data-col="4">Our algorithmic advantage is now delivered by machine learning</div>
            <div className="rule-select" data-row="6" data-col="5">Machine learning breakthroughs have made us the industry benchmark</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleThreeCompany;
