import React from 'react';
import { Link, Router } from 'react-router-dom'
import history from '../history';
import '../css/Footer.css';

const Footer = () => {
  return <footer className="footer-container h-flex">
      <Router history={history}>
        <div className="footer-left v-flex">
          <p className="site-link-title">Site Links</p>
          <ul className="v-flex">
            <li>
              <Link to="/">
                <p>Home</p>
              </Link>
            </li>
            <li>
              <Link to="/book">
                <p>About the Book</p>
              </Link>
            </li>
            <li>
              <Link to="/toddhewlin">
                <p>About Todd Hewlin</p>
              </Link>
            </li>
            <li>
              <Link to="/scottsnyder">
                <p>About Scott Synder</p>
              </Link>
            </li>
            <li>
              <Link to="/selfassessment">
                <p>Complete the Self-Assessment</p>
              </Link>
            </li>
          </ul>
        </div>
      </Router>

      <div className="footer-middle v-flex">
        <Router history={history}>
          <Link to="/privacypolicy">
            <p>Privacy Policy</p>
          </Link>
        </Router>
      </div>
      <div className="footer-right v-flex">
        <p>Copyright 2018</p>
      </div>
    </footer>;
}

export default Footer;
