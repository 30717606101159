import React from 'react';
import '../css/Loading.css';

const Loading = () => {
  return (
    <div className="loading-container">
      Loading...
    </div>
  );
}

export default Loading;
