import React from 'react';
import bookImg from '../assets/goliaths-revenge.png';
import '../css/BookLanding.css';

const BookLanding = () => {
  return (
    <div className="booklanding">
      <div className="booklanding-top h-flex">
        <div className="booklanding-top-left">
          <img src={bookImg} alt="book" />
        </div>

        <div className="booklanding-top-right">
          <h2 className="title">
            Harness your company’s incumbent advantages to win the digital disruption game.
          </h2>
          <p>
            Periods of intense change provide remarkable opportunities.  Goliath’s Revenge delivers an insider’s view of how industry leaders like General Motors, NASA, The Weather Channel,  Hitachi, Mastercard, Proctor & Gamble, Penn Medicine, Discovery, Cisco, and General Electric are accelerating innovation, building new skills, and disrupting themselves to come out stronger in this post-digital age. Learn how to leverage your company’s scale, reach, data, and expertise to launch breakthrough offerings that fend off attackers and secure your position as a future industry leader.
          </p>
          <br />
          <p>
            Using real success cases and recommendations, this invaluable resource shows how to realign your business model, reset your talent development priorities, and retake market share lost to digital-ready competitors. Drawing from extensive experience in digital transformation, leadership development, and strategic planning, the authors show how established companies can switch from defense to offense to thrive in this new digital environment.
          </p>
        </div>
      </div>
      <br />
      <ul className="bullet-point">
        <li><p>Learn the six new rules that separate winners from losers in the age of digital disruption</p></li>
        <li><p>Prioritize your innovation investments to rebuild your competitive moat</p></li>
        <li><p>Employ smart cannibalization to defend your core business</p></li>
        <li><p>Deliver step-change customer outcomes to grow into adjacent markets</p></li>
        <li><p>Reframe your purpose and make talent the centerpiece of your digital innovation strategy</p></li>
      </ul>
      <br />
      <p>
        <i>Goliath’s Revenge</i> is a must-read for business leaders and innovators in small, mid-sized, and large organizations trying to win the digital disruption game.  This book helps you reset both your company strategy and professional development priorities for long-term success.
      </p>
    </div>
  );
}

export default BookLanding;
