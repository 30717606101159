import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleOneCareer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "career",
      rule: 1,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.career.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.career,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule One</p>
            <p>Career Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>10X customer value</p>
            <div className="rule-select" data-row="1" data-col="1">Current role and past experience not related to customer value delivery</div>
            <div className="rule-select" data-row="1" data-col="2">Indirect role in the company's customer value delivery process</div>
            <div className="rule-select" data-row="1" data-col="3">Recognized experience delivering substantial customer value</div>
            <div className="rule-select" data-row="1" data-col="4">A past role was an essential part of 10X customer value delivery</div>
            <div className="rule-select" data-row="1" data-col="5">Current role is central to delivering the validated 10X customer outcome</div>
          </div>
          <div className="h-flex rule-block">
            <p>Incumbent's advantage</p>
            <div className="rule-select" data-row="2" data-col="1">Not involved with any of the crown jewels that support the step-change outcome</div>
            <div className="rule-select" data-row="2" data-col="2">On the team related to a crown jewel that supports the step-change outcome</div>
            <div className="rule-select" data-row="2" data-col="3">A major contributor to a crown jewel that supports the step-change outcome</div>
            <div className="rule-select" data-row="2" data-col="4">The go-to person for a crown jewel that supports the step-change outcome</div>
            <div className="rule-select" data-row="2" data-col="5">Played a leadership role on teams related to 2+ crown jewels over time</div>
          </div>
          <div className="h-flex rule-block">
            <p>Buyer persona outcomes</p>
            <div className="rule-select" data-row="3" data-col="1">Role does not entail any professional interactions with customers</div>
            <div className="rule-select" data-row="3" data-col="2">Support teams that interact with customers but do not do so personally</div>
            <div className="rule-select" data-row="3" data-col="3">Could independently qualify a customer as one of the 4 buyers personas</div>
            <div className="rule-select" data-row="3" data-col="4">Have professional relationships with customers to enable co-innovation efforts</div>
            <div className="rule-select" data-row="3" data-col="5">Have professional relationships with customers in each of the 4 buyer personas</div>
          </div>
          <div className="h-flex rule-block">
            <p>Whole offer clarity</p>
            <div className="rule-select" data-row="4" data-col="1">Can add minimal value to the creation of whole offer specifications</div>
            <div className="rule-select" data-row="4" data-col="2">Up-to-date with market research on customer needs and current competition</div>
            <div className="rule-select" data-row="4" data-col="3">Recognized expert on the unmet needs of customers in each buyer persona</div>
            <div className="rule-select" data-row="4" data-col="4">Go-to leader who can specify capability requirements for multiple whole offers</div>
            <div className="rule-select" data-row="4" data-col="5">Recognized expert for Build vs. Partner decisions within multiple whole offers</div>
          </div>
          <div className="h-flex rule-block">
            <p>Whole offer ecosystem</p>
            <div className="rule-select" data-row="5" data-col="1">Can add minimal value to whole offer partner selection and contracting</div>
            <div className="rule-select" data-row="5" data-col="2">Up-to-date with the capabilities of potential whole offer partners</div>
            <div className="rule-select" data-row="5" data-col="3">Past experience validating and contracting with whole offer partners</div>
            <div className="rule-select" data-row="5" data-col="4">Recognized expert within an important category of whole offer partners</div>
            <div className="rule-select" data-row="5" data-col="5">Recognized expert across multiple categories of whole offer partners</div>
          </div>
          <div className="h-flex rule-block">
            <p>Time to market</p>
            <div className="rule-select" data-row="6" data-col="1">Minimal training and experience in past time-to-market initiatives</div>
            <div className="rule-select" data-row="6" data-col="2">Trained in Agile methodology and co-innovation initiatives</div>
            <div className="rule-select" data-row="6" data-col="3">In-demand team member for urgent projects and strategic initiatives</div>
            <div className="rule-select" data-row="6" data-col="4">Experienced team leader for urgent projects and strategic initiatives</div>
            <div className="rule-select" data-row="6" data-col="5">Recognized company wide for excellence in urgent strategic initiatives</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleOneCareer;
