import React, { Component } from 'react';

class PDFRuleFourCompany extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.companyArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-company-4-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #4: Accelerate Via Innovation Networks Company Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Four</p>
            <p>Company</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Beyond "We Know Everything"</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-1"><p>Our culture is highly resistant to external innovations</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-1"><p>6 of the 8 open innovation inhibitors are still in place</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-1"><p>4 of the 8 open innovation inhibitors are still in place</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-1"><p>2 of the 8 open innovation inhibitors are still in place</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-4-1"><p>All 8 open innovation inhibitors have been resolved</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Multiple innovation channels</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-2"><p>Zero or 1 of the open innovation channels have been activated</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-2"><p>2 of the 8 open innovation channels have been activated</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-2"><p>4 of the 8 open innovation channels have been activated</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-2"><p>6 of the 8 open innovation channels have been activated</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-4-2"><p>All 8 open innovation channels have been activated</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Right gives and gets</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-3"><p>The vast majority of the ideas in our innovation funnel come from internal sources</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-3"><p>At least 10% of the ideas in our innovation funnel come from external sources</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-3"><p>At least 30% of the ideas in our innovation funnel come from external sources</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-3"><p>At least 50% of the ideas in our innovation funnel come from external sources</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-4-3"><p>Over 70% of the ideas in our innovation funnel come from external sources</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Easy to innovate with</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-4"><p>We are just starting out and none of the 8 best practices describe our company today</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-4"><p>At least 2 of the 8 best practices apply to how we run our innovation network</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-4"><p>At least 4 of the 8 best practices apply to how we run our innovation network</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-4"><p>At least 6 of the 8 best practices apply to how we run our innovation network</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-4-4"><p>Every one of the 8 best practices apply to how we run our innovation network</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Robust innovation sandbox</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-5"><p>We do not yet allow external access to sample data or our technology</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-5"><p>Innovation sandbox is built on a one-off basis for specific external partners</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-5"><p>Substantial sample data is available for startups to experiment with</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-5"><p>Have now extended beyond data toward API-based access to our technology</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-4-5"><p>Deep APIs, massive sample data sets, and valuable technology available today</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Corporate development toolkit</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-6"><p>We do not have a formal Corporate Development capability today</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-6"><p>We have limited, ad hoc experience in Corporate Development today</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-6"><p>We have substantial experience in 2 of the 6 Corporate Development tools</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-4-6"><p>We have substantial experience in 4 of the 6 Corporate Development tools</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-4-6"><p>We have substantial experience in each of the 6 Corporate Development tools</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">17</p>
      </div>
    );
  }

}

export default PDFRuleFourCompany;
