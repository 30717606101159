import React from 'react';
import '../App.css';

const PrivacyPolicy = () => {
  return <div className="policy-container">
      <h3 className="title">Privacy Policy</h3>
      <div className="policy-content">
        <p>Effective date: November 08, 2018</p>
        <br />
        <p>
          Goliath's Revenge ("us", "we", or "our") operates the
          www.goliathrevenge.com website and the Goliath's Revenge mobile
          application (the "Service")
        </p>
        <br />
        <p>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data. Our Privacy Policy for
          Goliath's Revenge is managed through Free Privacy Policy.
        </p>
        <br />
        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this
          Privacy Policy, terms used in this Privacy Policy have the same
          meanings as in our Terms and Conditions.
        </p>
        <br />
        <p>Information Collection And Use</p>
        <p>
          We collect several different types of information for various
          purposes to provide and improve our Service to you.
        </p>
        <br />
        <p>Types of Data Collected</p>
        <p>Personal Data</p>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <br />
        <p>Email address</p>
        <p>Cookies and Usage Data</p>
        <p>Usage Data</p>
        <p>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access the Service by or through a
          mobile device("Usage Data")
        </p>
        <br />
        <p>
          This Usage Data may include information such as your computer's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date
          of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
        </p>
        <br />
        <p>Tracking & Cookies Data</p>
        <p>
          We use cookies and similar tracking technologies to track the
          activity on our Service and hold certain information.
        </p>
        <br />
        <p>
          Cookies are files with small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Tracking technologies also used
          are beacons, tags, and scripts to collect and track information and
          to improve and analyze our Service.
        </p>
        <br />
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </p>
        <br />
        <p>Examples of Cookies we use:</p>
        <br />
        <p>Session Cookies. We use Session Cookies to operate our Service.</p>
        <p>
          Preference Cookies. We use Preference Cookies to remember your
          preferences and various settings.
        </p>
        <p>
          Security Cookies. We use Security Cookies for security purposes.
        </p>
        <p>Use of Data</p>
        <p>Goliath's Revenge uses the collected data for various purposes:</p>
        <br />
        <p>To provide and maintain the Service</p>
        <p>To notify you about changes to our Service</p>
        <p>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </p>
        <p>To provide customer care and support</p>
        <p>
          To provide analysis or valuable information so that we can improve
          the Service
        </p>
        <p>To monitor the usage of the Service</p>
        <p>To detect, prevent and address technical issues</p>
        <p>Transfer Of Data</p>
        <p>
          Your information, including Personal Data, may be transferred to -
          and maintained on - computers located outside of your state,
          province, country or other governmental jurisdiction where the data
          protection laws may differ than those from your jurisdiction.
        </p>
        <br />
        <p>
          If you are located outside United States and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there.
        </p>
        <br />
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <br />
        <p>
          Goliath's Revenge will take all steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this
          Privacy Policy and no transfer of your Personal Data will take place
          to an organization or a country unless there are adequate controls
          in place including the security of your data and other personal
          information
        </p>
        <br />
        <p>Disclosure Of Data</p>
        <p>Legal Requirements</p>
        <p>
          Goliath's Revenge may disclose your Personal Data in the good faith
          belief that such action is necessary to:
        </p>
        <br />
        <p>To comply with a legal obligation</p>
        <p>
          To protect and defend the rights or property of Goliath's Revenge
        </p>
        <p>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </p>
        <p>
          To protect the personal safety of users of the Service or the public
        </p>
        <p>To protect against legal liability</p>
        <br />
        <p>Security Of Data</p>
        <p>
          The security of your data is important to us, but remember that no
          method of transmission over the internet, or method of electronic
          storage is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Data, we cannot guarantee
          its absolute security.
        </p>
        <br />
        <p>Service Provides</p>
        <p>
          We may employ third party companies and individuals to facilitate
          our Service ("Service Provides"), to provide the Service on our
          behalf, to perform Service-related services or to assist us in
          analyzing how our Service is used.
        </p>
        <br />
        <p>
          These third parties have access to your Personal Data only to
          perform these tasks on our behalf and are obligated not to disclose
          or use it for any other purpose.
        </p>
        <br />
        <p>Analytics</p>
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </p>
        <br />
        <p>Google Analytics</p>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected
          to track and monitor the use of our Service. This data is shared
          with other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising
          network.
        </p>
        <br />
        <p>
          For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">
            https://policies.google.com/privacy?hl=en
          </a>
        </p>
        <br />
        <p>Links To Other Sites</p>
        <p>
          Our Service may contain links to other sites that are not operated
          by us. If you click on a third party link, you will be directed to
          that third party's site. We strongly advise you to review the
          Privacy Policy of every site you visit.
        </p>
        <br />
        <p>
          We have no control over and assume no responsibility for the
          content, privacy policies or practices of any third party sites or
          services.
        </p>
        <br />
        <p>Children's Privacy</p>
        <p>
          Our Service does not address anyone under the age of 18
          ("Children").
        </p>
        <br />
        <p>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children has provided us with Personal Data,
          please contact us. If we become aware that we have collected
          Personal Data from children without verification of parental
          consent, we take steps to remove that information from our servers.
        </p>
        <br />
        <p>Changes To This Privacy Policy</p>
        <p>
          We may update our Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page.
        </p>
        <br />
        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <br />
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <br />
        <p>Contact Us</p>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <br />
        <p>By email: info@tcg-advisors.com</p>
      </div>
    </div>;
}

export default PrivacyPolicy;
