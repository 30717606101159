import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleFourCareer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "career",
      rule: 4,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.career.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.career,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Four</p>
            <p>Career Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>Beyond "We Know Everything"</p>
            <div className="rule-select" data-row="1" data-col="1">Cannot understand why things need to keep changing so often</div>
            <div className="rule-select" data-row="1" data-col="2">Understand that big changes are afoot but don't see my role in making them happen</div>
            <div className="rule-select" data-row="1" data-col="3">I am naturally curious and a committed lifelong learner</div>
            <div className="rule-select" data-row="1" data-col="4">I am well-versed on digital transformation and actively leading change in my area</div>
            <div className="rule-select" data-row="1" data-col="5">Am well known for evangelizing new ideas across our company</div>
          </div>
          <div className="h-flex rule-block">
            <p>Multiple innovation channels</p>
            <div className="rule-select" data-row="2" data-col="1">Have not been involved in co-innovation projects with external partners</div>
            <div className="rule-select" data-row="2" data-col="2">Have worked on co-innovation projects across 2 of the 8 innovation channels</div>
            <div className="rule-select" data-row="2" data-col="3">Have worked on co-innovation projects across 4 of the 8 innovation channels</div>
            <div className="rule-select" data-row="2" data-col="4">Have worked on co-innovation projects across 6 of the 8 innovation channels</div>
            <div className="rule-select" data-row="2" data-col="5">Have worked on co-innovation projects across all 8 of the innovation channels</div>
          </div>
          <div className="h-flex rule-block">
            <p>Right gives and gets</p>
            <div className="rule-select" data-row="3" data-col="1">Not involved in adding innovation partners to our company's network</div>
            <div className="rule-select" data-row="3" data-col="2">Provide support to the teams that are activating new innovation partners</div>
            <div className="rule-select" data-row="3" data-col="3">Deeply involved in negotiating new partnerships with external innovators</div>
            <div className="rule-select" data-row="3" data-col="4">Lead our company's efforts within one of the open innovation channels</div>
            <div className="rule-select" data-row="3" data-col="5">Lead our company's efforts across multiple open innovation channels</div>
          </div>
          <div className="h-flex rule-block">
            <p>Easy to innovate with</p>
            <div className="rule-select" data-row="4" data-col="1">No previous experience in making our open innovation approach better</div>
            <div className="rule-select" data-row="4" data-col="2">Have identified areas where our company can make open innovation easier</div>
            <div className="rule-select" data-row="4" data-col="3">Active team member on multiple projects to streamline our open innovation process</div>
            <div className="rule-select" data-row="4" data-col="4">Lead our company's effort to design the friction out of open innovation</div>
            <div className="rule-select" data-row="4" data-col="5">Am recognized across the industry as a thought leader on open innovation</div>
          </div>
          <div className="h-flex rule-block">
            <p>Robust innovation sandbox</p>
            <div className="rule-select" data-row="5" data-col="1">Not working as part of our open innovation sandbox teams</div>
            <div className="rule-select" data-row="5" data-col="2">Member of one of our key sandbox teams - APIs, data sets, or technology tools</div>
            <div className="rule-select" data-row="5" data-col="3">Lead one of our key sandbox teams - APIs, data sets, or technology tools</div>
            <div className="rule-select" data-row="5" data-col="4">Drive our ongoing efforts to make our open innovation sandbox better</div>
            <div className="rule-select" data-row="5" data-col="5">Lead our efforts to engage external innovators with our data and tools</div>
          </div>
          <div className="h-flex rule-block">
            <p>Corporate development toolkit</p>
            <div className="rule-select" data-row="6" data-col="1">1+ No experience yet executing Corporate Development deals</div>
            <div className="rule-select" data-row="6" data-col="2">Have worked on deal teams across 1 of our deal structures</div>
            <div className="rule-select" data-row="6" data-col="3">Have worked on deal teams across multiple deal structures</div>
            <div className="rule-select" data-row="6" data-col="4">Have led deals within 1 of our Corporate Development structures</div>
            <div className="rule-select" data-row="6" data-col="5">Have led deals across multiple Corporate Development structures</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleFourCareer;
