import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleSixCareer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "career",
      rule: 6,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.career.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.career,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Six</p>
            <p>Career Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>Raise your sights</p>
            <div className="rule-select" data-row="1" data-col="1">My team is just "heads down" trying to deliver on our performance metrics</div>
            <div className="rule-select" data-row="1" data-col="2">My team is aligned to our company's purpose but does not have its own</div>
            <div className="rule-select" data-row="1" data-col="3">My team has a draft statement of purpose that is still being refined</div>
            <div className="rule-select" data-row="1" data-col="4">Mission / vision for my team is compelling but not sure if it aligns to our company overall</div>
            <div className="rule-select" data-row="1" data-col="5">Mission / vision for my team is compelling and aligned to that of our company</div>
          </div>
          <div className="h-flex rule-block">
            <p>Answer the "Five Whys"</p>
            <div className="rule-select" data-row="2" data-col="1">Our company is doing this "Five Whys" effort but don't know how that effects me</div>
            <div className="rule-select" data-row="2" data-col="2">My career path is built around helping our company deliver the 2nd "why"</div>
            <div className="rule-select" data-row="2" data-col="3">My career path is built around helping our company deliver the 3rd "why"</div>
            <div className="rule-select" data-row="2" data-col="4">My career path is built around helping our company deliver the 4th "why"</div>
            <div className="rule-select" data-row="2" data-col="5">My career path is built around helping our company deliver the 5th "why"</div>
          </div>
          <div className="h-flex rule-block">
            <p>Embrace smart cannibalization</p>
            <div className="rule-select" data-row="3" data-col="1">If our company has a two-speed organization model, I don't know about it</div>
            <div className="rule-select" data-row="3" data-col="2">I have had deep experience in speed one team but not in speed two</div>
            <div className="rule-select" data-row="3" data-col="3">I have had deep experience in speed two teams but not in speed one</div>
            <div className="rule-select" data-row="3" data-col="4">Have worked in both speed one and two teams but am not equally proficient yet</div>
            <div className="rule-select" data-row="3" data-col="5">I am ambidextrous; I have excelled in both speed one and speed two teams</div>
          </div>
          <div className="h-flex rule-block">
            <p>Engage the next generation</p>
            <div className="rule-select" data-row="4" data-col="1">Not sure what all the fuss about with these social and environmental issues</div>
            <div className="rule-select" data-row="4" data-col="2">Just starting to appreciate that Digital Natives are a breath of fresh air</div>
            <div className="rule-select" data-row="4" data-col="3">I have adjusted my working style over time to better relate t Digital Natives</div>
            <div className="rule-select" data-row="4" data-col="4">I am an active mentor to Digital Natives within and beyond my current team</div>
            <div className="rule-select" data-row="4" data-col="5">I built our triple bottom line focus into how my team runs day-to-day</div>
          </div>
          <div className="h-flex rule-block">
            <p>Align top-down</p>
            <div className="rule-select" data-row="5" data-col="1">Don't think alignment is worth the effort; why can't everyone just do their jobs?</div>
            <div className="rule-select" data-row="5" data-col="2">My team has started talking about alignment but not sure we can get there</div>
            <div className="rule-select" data-row="5" data-col="3">I drive alignment within my team but not out to peers or up the organization chart</div>
            <div className="rule-select" data-row="5" data-col="4">I drive alignment with my team and peers but have limited influence upward</div>
            <div className="rule-select" data-row="5" data-col="5">I personally drive alignment within the 360 degree sphere around my role</div>
          </div>
          <div className="h-flex rule-block">
            <p>Lead by example</p>
            <div className="rule-select" data-row="6" data-col="1">These personal statements of purpose are just a waste of time</div>
            <div className="rule-select" data-row="6" data-col="2">I am ready to commit time and energy to figuring out what my personal purpose is</div>
            <div className="rule-select" data-row="6" data-col="3">Have ideas about my personal purpose but have not written it down in a formal way</div>
            <div className="rule-select" data-row="6" data-col="4">My personal purpose is compelling but not sure if it aligns to our company very well</div>
            <div className="rule-select" data-row="6" data-col="5">My personal purpose is compelling and aligned with our company's purpose</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleSixCareer;
