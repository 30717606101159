import React from 'react';
import { Route, Router } from 'react-router-dom';
import App from './App';
import Loading from './components/Loading';
import Auth from './components/Auth';
import SelfAssessment from './pages/SelfAssessment';
import Header from './components/Header';
import Footer from './components/Footer';
import history from './history';

const auth = new Auth();

const handleAuthentication = ({location}) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}

export const makeMainRoutes = () => {
  return (
    <div>
      <Header />
      <Router history={history}>
        <div>
          <Route path="/" render={(props) => <App {...props} />} />
          <Route
            path="/selfassessment"
            render={props => <SelfAssessment {...props}
              auth={auth}
            />}
          />
          <Route path="/loading" render={(props) => {
            handleAuthentication(props);
            return <Loading {...props} />
          }}/>
        </div>
      </Router>
      <Footer />
    </div>
  );
}
