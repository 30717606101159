import React from 'react';
import Quotes from './Quotes';
import '../css/Landing.css';
import bookImg from '../assets/goliaths-revenge.png';

const Landing = () => {
  return (
    <div className="landing">
      <div className="landing-container h-flex">
        <div className="landing-left">
          <img src={bookImg} alt="Goliath's Revenge Book"/>
        </div>

        <div className="landing-right">
          <div className="landing-top">
            <p>
            <span>Goliath's Revenge</span> is the practical guide for how executives and aspiring leaders of established companies can run the Silicon Valley playbook for themsevles and capitalize on digital disruption. Technologies like artificial intelligence, robotics, internet of things, blockchain, and immersive experiences are changing the basis of competition in every industry. Goliath’s Revenge delivers an insider’s view of how industry leaders like General Motors, NASA, The Weather Channel,  Hitachi, Mastercard, General Electric, Proctor & Gamble, Penn Medicine, Discovery, and Cisco are putting these disruptions to work to both defend their core businesses and grow into adjacent markets.
            </p>
          </div>
          <br />
          <Quotes />

        </div>
      </div>
    </div>
  );
}

export default Landing;
