import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleFiveCareer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "career",
      rule: 5,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.career.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.career,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
    .then(() => {
      this.props.getResult();
    })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Five</p>
            <p>Career Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>Honor institutional knowledge</p>
            <div className="rule-select" data-row="1" data-col="1">Sometimes viewed as divisive and political even if you are not really like that</div>
            <div className="rule-select" data-row="1" data-col="2">Open to working with people from different organizations if required</div>
            <div className="rule-select" data-row="1" data-col="3">Proven ability to work equally well with veteran staff and new digital hires alike</div>
            <div className="rule-select" data-row="1" data-col="4">Viewed by peers as a great connector between disparate organizations</div>
            <div className="rule-select" data-row="1" data-col="5">In-demand to lead teams that leverage long-term staff and new digital hires</div>
          </div>
          <div className="h-flex rule-block">
            <p>Go beyond "3D" digital roles</p>
            <div className="rule-select" data-row="2" data-col="1">No experience yet in the "3D" roles let alone the six new digital roles</div>
            <div className="rule-select" data-row="2" data-col="2">Experience in at least one of the design, development, data science roles</div>
            <div className="rule-select" data-row="2" data-col="3">Have completed job rotations in at least one of the six new digital roles</div>
            <div className="rule-select" data-row="2" data-col="4">Have completed job rotations in at least two of the six new digital roles</div>
            <div className="rule-select" data-row="2" data-col="5">Significant experience in at least three of the six new "beyond 3D" digital roles</div>
          </div>
          <div className="h-flex rule-block">
            <p>Commit to pre-emptive skill development</p>
            <div className="rule-select" data-row="3" data-col="1">Unclear on your professional skill gaps relative to future digital demand</div>
            <div className="rule-select" data-row="3" data-col="2">Understand your skill gaps but have not done anything to address them</div>
            <div className="rule-select" data-row="3" data-col="3">Have made lateral career move to build your digital skill set</div>
            <div className="rule-select" data-row="3" data-col="4">Have taken online courses on personal time to develop digital skills</div>
            <div className="rule-select" data-row="3" data-col="5">Already worked in paired jobs for rapid skill development</div>
          </div>
          <div className="h-flex rule-block">
            <p>Value Venture General Managers</p>
            <div className="rule-select" data-row="4" data-col="1">Have no general manager skills to speak of</div>
            <div className="rule-select" data-row="4" data-col="2">Have general manager skills but only in a mature business</div>
            <div className="rule-select" data-row="4" data-col="3">Have led a successful innovation ventures</div>
            <div className="rule-select" data-row="4" data-col="4">Strong results leading many innovation teams</div>
            <div className="rule-select" data-row="4" data-col="5">Peers seek your mentorship to build Venture GM skills</div>
          </div>
          <div className="h-flex rule-block">
            <p>Optimize the AI-Human Balance</p>
            <div className="rule-select" data-row="5" data-col="1">Act defensively when presented with AI projects and initiatives</div>
            <div className="rule-select" data-row="5" data-col="2">View AI suspiciously as it regards your future career</div>
            <div className="rule-select" data-row="5" data-col="3">Have led AI projects that delivered their promised results</div>
            <div className="rule-select" data-row="5" data-col="4">Expert at getting AI and Humans working well</div>
            <div className="rule-select" data-row="5" data-col="5">Viewed as AI innovator that is savvy about human impact</div>
          </div>
          <div className="h-flex rule-block">
            <p>Improve your digital dexterity</p>
            <div className="rule-select" data-row="6" data-col="1">Do not yet demonstrate any drivers of digital dexterity</div>
            <div className="rule-select" data-row="6" data-col="2">Demonstrate at least 3 drivers of digital dexterity</div>
            <div className="rule-select" data-row="6" data-col="3">Demonstrate at least 6 drivers of digital dexterity</div>
            <div className="rule-select" data-row="6" data-col="4">Demonstrate at least 9 drivers of digital dexterity</div>
            <div className="rule-select" data-row="6" data-col="5">Demonstrate all 12 drivers of digital dexterity</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleFiveCareer;
