import React from 'react';
import book from '../assets/goliaths-revenge.png';

// import { Link, Router } from 'react-router-dom'

const PDFCover = (props) => {
  return (
    <div className="pdf-page v-flex">
      <p className="pdf-footer">Goliath's Revenge Self-Assessment Results</p>
      <h1 className="pdf-title pdf-title-cover">Goliath's Revenge Self-Assessment Results</h1>
      <h3 className="pdf-title-cover">{props.userEmail}</h3>
      <div className="pdf-cover-img">
        <img src={book} alt="Goliath's Revenge"/>
      </div>
      <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
      <p className="pdf-footer-number">1</p>
    </div>
  );
}

export default PDFCover;
