import React from 'react';
import '../css/NotAuthorized.css';
import slingshot from '../assets/slingshot.png';

const NotAuthorized = ({ hash, auth }) => {
  return (
    <div className="notauthorized">
      <div className="notauthorized-container v-flex">
        <div className="notauthorized-block h-flex">
          <div>
            <img src={slingshot} alt="slingshot" />
          </div>
          <h3 className="title">
            How ready are you for a future defined by digital disruption?
          </h3>
        </div>
        <p>
            The six new rules below separate the winners from the losers in these times of digital disruption. Within each new rule, you get the chance to self-assess the readiness of both your company and your career against the best practices and case examples covered in Goliath’s Revenge. You can either complete the full self-assessment at one time or come back to individual new rules as you read the corresponding chapters in the book. Once you’ve completed the self-assessment, you will receive a free 22-page PDF report that summarizes your readiness for the digital future.
          </p>
      </div>

      <div className="rule-list v-flex">
        <div className="rule-list-1 h-flex">
          <div className="rule-list-left h-flex">
            <p className="rule-lable">Rule #1</p>
            <div className="rule-text v-flex">
              <p>Deliver Step-Change</p>
              <p>Customer Outcomes</p>
            </div>

          </div>
          <div className="rule-list-right h-flex">
            <p className="rule-lable">Rule #2</p>
            <div className="rule-text v-flex">
              <p>Pursue Big I and</p>
              <p>Little I Innovation</p>
            </div>
          </div>
        </div>

        <div className="rule-list-2 h-flex">
          <div className="rule-list-left h-flex">
            <p className="rule-lable">Rule #3</p>
            <div className="rule-text v-flex">
              <p>Use Your Data as</p>
              <p>Currency</p>
            </div>
          </div>
          <div className="rule-list-right h-flex">
            <p className="rule-lable">Rule #4</p>
            <div className="rule-text v-flex">
              <p>Accelerate Through</p>
              <p>Innovation Networks</p>
            </div>
          </div>
        </div>

        <div className="rule-list-3 h-flex">
          <div className="rule-list-left h-flex">
            <p className="rule-lable">Rule #5</p>
            <div className="rule-text v-flex">
              <p>Value Talent Over</p>
              <p>Technology</p>
            </div>
          </div>
          <div className="rule-list-right h-flex">
            <p className="rule-lable">Rule #6</p>
            <div className="rule-text v-flex">
              <p>Reframe Your</p>
              <p>Purpose</p>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default NotAuthorized;
