import React from 'react';
import '../css/Book.css';
import BookLanding from '../components/BookLanding';
import Subscribe from '../components/Subscribe';
import CompanyLogo from '../components/CompanyLogo';

const Book = () => {
  return (
    <div className="book">
      <BookLanding />
      <Subscribe />
      <CompanyLogo />
    </div>
  );
}

export default Book;
