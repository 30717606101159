import React, { Component } from 'react';

class PDFRuleFiveCompany extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.companyArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-company-6-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #6: Reframe Your Purpose Company Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Six</p>
            <p>Company</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Raise your sights</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-1"><p>We don't believe in all that mission / vision stuff here</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-1"><p>Mission / vision too much about us; not enough about the world</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-1"><p>Vision is clear but we haven't internalized it into our mission</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-1"><p>Mission is right but vision may not be right for the long-term</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-6-1"><p>Mission / vision so compelling that everyone can recite them</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Answer the "Five Whys"</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-2"><p>We struggle to even answer the superficial first "why"</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-2"><p>Aligned at least 2 "whys" deep on why we do what we do</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-2"><p>Aligned at least 3 "whys" deep on why we do what we do</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-2"><p>Aligned at least 4 "whys" deep on why we do what we do</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-6-2"><p>Aligned at least 5 "whys" deep on why we do what we do</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Embrace smart cannibalization</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-3"><p>We are on a path to be the Kodak of our industry</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-3"><p>Only executing "speed two" model in adjacent markets; not our own</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-3"><p>Speed two model has permission for smart cannibalization but just launched</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-3"><p>Two-speed model in place but "haves and have nots" issues are still being worked on</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-6-3"><p>Two-speed organizational model in place and delivering well</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Engage the next generation</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-4"><p>Do not think Digital Natives would enjoy working for our company</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-4"><p>Beginning to get how Millennial and Gen Z employees are different</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-4"><p>We support employees who want to make a difference outside of work</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-4"><p>We've added either social or environmental goals to our financial ones</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-6-4"><p>We live and breath our triple bottom line every day</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Align top-down</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-5"><p>Every group has its own mission and vision so no alignment at company level</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-5"><p>Alignment is superficial today; not translated into day-to-day actions</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-5"><p>Board and senior leaders aligned but has not reached the staff doing real work</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-5"><p>Middle management to front line alignment in place but senior leaders are still TBD</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-6-5"><p>Broad and deep alignment from Board to front-line to partners in place</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Lead by example</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-6"><p>Our leaders seem near-sighted; solely focused on delivering near-term profits</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-6"><p>Our leaders might have strong personal purposes but I don't know what they are</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-6"><p>Some leaders have strong personal purposes but others not so much</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-company-6-6"><p>Our leaders have strong personal purposes but may not align to company</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-company-6-6"><p>Leaders and genuinely authentic with company-aligned personal purposes</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">21</p>
      </div>
    );
  }

}

export default PDFRuleFiveCompany;
