import React from 'react';
import CookieConsent from 'react-cookie-consent';
import '../css/SelfAssessment.css';

const CookieConsentPop = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Accept'
      style={{
        background: '#5F6C88'
      }}
      buttonStlye={{
        color: '#ECECEC'
      }}
      buttonClasses='rule-lable'
      expires={150}
    >
      This site uses cookies. By clicking on the Accept button, you are agreeing to our use of cookies. Review our <a href="/privacypolicy">Privacy Policy</a> for more details.
    </CookieConsent>
  )
}

export default CookieConsentPop;
