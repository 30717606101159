import React, { Component } from 'react';
import { Router, Redirect } from 'react-router-dom';
import history from '../history';

class RuleNavBottom extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectClick: false
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.updateDataBase(e);
    this.setState({
      redirectClick: true
    })
  }

  render() {

    if (this.state.redirectClick) {
      return (
        <Redirect to="/selfassessment" />
      )
    } else {
      return (
        <div>
          <Router history={history}>
            <div className="h-flex">
              <button type="submit" onClick={this.handleClick} className="dash-rule-lable">Save and Return to Summary</button>
            </div>
          </Router>
        </div>
      )
    }
  }
}

export default RuleNavBottom;
