import React from 'react';
import slingshot from '../assets/slingshot.png';

const DashBoardHeading = () => {
  return (
    <div className="dashboard-heading h-flex">
      <div className="dashboard-heading-left">
        <img src={slingshot} alt="sling shot" />
      </div>
      <div className="dashboard-heading-right v-flex">
        <h2 className="title">Your readiness summary for Goliath's Revenge</h2>
        <p>The self-assessment includes two grids for each of the six new rules of Goliath's Revenge. See below for the current progress you've made in completing each grid and the current average capability rating that you've assigned to your company and your career within each area.</p>
        <br />
        <p>To complete the self-assessment or adjust your previous ratings, just click on one of the new rules boxes below. When you have completed all of the self-assessment grids, just click on the Get PDF Report box at the bottom to generate your free 22-page readiness report.</p>
      </div>
    </div>
  );
}

export default DashBoardHeading;
