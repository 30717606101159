import React, { Component } from 'react';

// import { Link, Router } from 'react-router-dom'

class PDFRuleFour extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const companyRuleArr = this.props.companyRuleArr.split('');
    const careerRuleArr = this.props.careerRuleArr.split('');

    const companySideBottom = document.getElementsByClassName(`pdf-chart-left-bottom-4`)
    const careerSideBottom = document.getElementsByClassName(`pdf-chart-right-bottom-4`)
    const companyFill = Math.floor(companyRuleArr.reduce((acc, cur) => { return acc + parseInt(cur) }, 0) / 6)
    const careerFill = Math.floor(careerRuleArr.reduce((acc, cur) => { return acc + parseInt(cur) }, 0) / 6)

    for (let i = 0; i < 6; i++) {
      const companySide = document.getElementsByClassName(`pdf-chart-left-4-${i + 1}`)
      const careerSide = document.getElementsByClassName(`pdf-chart-right-4-${i + 1}`)


      for (let j = companyRuleArr[i] - 1; j >= 0; j--) {
        companySide[j].classList.add('pdf-table-filled-blue')
      }
      for (let k = 0; k < careerRuleArr[i]; k++) {
        careerSide[k].classList.add('pdf-table-filled-blue')
      }
      for (let m = 0; m < companyFill; m++) {
        companySideBottom[0].childNodes[m].classList.add('pdf-table-filled-orange-2')
      }
      for (let n = 0; n < careerFill; n++) {
        careerSideBottom[0].childNodes[n].classList.add('pdf-table-filled-orange-2')
      }
    }
  }

  render() {

    const companyRuleArr = this.props.companyRuleArr.split('');
    const careerRuleArr = this.props.careerRuleArr.split('');

    const companyPercent = Math.floor(companyRuleArr.reduce((acc, cur) => { return acc + parseInt(cur) }, 0) / 0.3)
    const careerPercent = Math.floor(careerRuleArr.reduce((acc, cur) => { return acc + parseInt(cur) }, 0) / 0.3)

    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h1 className="pdf-title">Rule #4: ACCELERATE VIA INNOVATION NETWORKS READINESS SUMMARY</h1>
        <div className="pdf-chart-title h-flex">
          <div className="pdf-chart-title-left"><h2>Company Readiness</h2></div>
          <div className="pdf-chart-title-middle"></div>
          <div className="pdf-chart-title-right"><h2>Career Readiness</h2></div>
        </div>
        <div className="pdf-chart h-flex">
          <div className="pdf-chart-left v-flex">
            <div className="h-flex-reverse">
              <div className="pdf-chart-left-4-1"></div>
              <div className="pdf-chart-left-4-1"></div>
              <div className="pdf-chart-left-4-1"></div>
              <div className="pdf-chart-left-4-1"></div>
              <div className="pdf-chart-left-4-1"></div>
            </div>
            <div className="h-flex-reverse">
              <div className="pdf-chart-left-4-2"></div>
              <div className="pdf-chart-left-4-2"></div>
              <div className="pdf-chart-left-4-2"></div>
              <div className="pdf-chart-left-4-2"></div>
              <div className="pdf-chart-left-4-2"></div>
            </div>
            <div className="h-flex-reverse">
              <div className="pdf-chart-left-4-3"></div>
              <div className="pdf-chart-left-4-3"></div>
              <div className="pdf-chart-left-4-3"></div>
              <div className="pdf-chart-left-4-3"></div>
              <div className="pdf-chart-left-4-3"></div>
            </div>
            <div className="h-flex-reverse">
              <div className="pdf-chart-left-4-4"></div>
              <div className="pdf-chart-left-4-4"></div>
              <div className="pdf-chart-left-4-4"></div>
              <div className="pdf-chart-left-4-4"></div>
              <div className="pdf-chart-left-4-4"></div>
            </div>
            <div className="h-flex-reverse">
              <div className="pdf-chart-left-4-5"></div>
              <div className="pdf-chart-left-4-5"></div>
              <div className="pdf-chart-left-4-5"></div>
              <div className="pdf-chart-left-4-5"></div>
              <div className="pdf-chart-left-4-5"></div>
            </div>
            <div className="h-flex-reverse">
              <div className="pdf-chart-left-4-6"></div>
              <div className="pdf-chart-left-4-6"></div>
              <div className="pdf-chart-left-4-6"></div>
              <div className="pdf-chart-left-4-6"></div>
              <div className="pdf-chart-left-4-6"></div>
            </div>
            <div className="pdf-chart-left-bottom-4 pdf-chart-bottom h-flex-reverse">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="pdf-chart-middle v-flex">
            <div className="pdf-chart-middle-div"><p>Beyond "We Know Everything"</p></div>
            <div className="pdf-chart-middle-div"><p>Multiple innovation channels</p></div>
            <div className="pdf-chart-middle-div"><p>Right gives and gets</p></div>
            <div className="pdf-chart-middle-div"><p>Easy to innovate with</p></div>
            <div className="pdf-chart-middle-div"><p>Robust innovation sandbox</p></div>
            <div className="pdf-chart-middle-div"><p>Corporate development toolkit</p></div>
            <div className="pdf-chart-middle-bottom h-flex">
              <p>{companyPercent}%</p>
              <p>{careerPercent}%</p>
            </div>
          </div>
          <div className="pdf-chart-right v-flex">
            <div className="h-flex">
              <div className="pdf-chart-right-4-1"></div>
              <div className="pdf-chart-right-4-1"></div>
              <div className="pdf-chart-right-4-1"></div>
              <div className="pdf-chart-right-4-1"></div>
              <div className="pdf-chart-right-4-1"></div>
            </div>
            <div className="h-flex">
              <div className="pdf-chart-right-4-2"></div>
              <div className="pdf-chart-right-4-2"></div>
              <div className="pdf-chart-right-4-2"></div>
              <div className="pdf-chart-right-4-2"></div>
              <div className="pdf-chart-right-4-2"></div>
            </div>
            <div className="h-flex">
              <div className="pdf-chart-right-4-3"></div>
              <div className="pdf-chart-right-4-3"></div>
              <div className="pdf-chart-right-4-3"></div>
              <div className="pdf-chart-right-4-3"></div>
              <div className="pdf-chart-right-4-3"></div>
            </div>
            <div className="h-flex">
              <div className="pdf-chart-right-4-4"></div>
              <div className="pdf-chart-right-4-4"></div>
              <div className="pdf-chart-right-4-4"></div>
              <div className="pdf-chart-right-4-4"></div>
              <div className="pdf-chart-right-4-4"></div>
            </div>
            <div className="h-flex">
              <div className="pdf-chart-right-4-5"></div>
              <div className="pdf-chart-right-4-5"></div>
              <div className="pdf-chart-right-4-5"></div>
              <div className="pdf-chart-right-4-5"></div>
              <div className="pdf-chart-right-4-5"></div>
            </div>
            <div className="h-flex">
              <div className="pdf-chart-right-4-6"></div>
              <div className="pdf-chart-right-4-6"></div>
              <div className="pdf-chart-right-4-6"></div>
              <div className="pdf-chart-right-4-6"></div>
              <div className="pdf-chart-right-4-6"></div>
            </div>
            <div className="pdf-chart-right-bottom-4 pdf-chart-bottom h-flex">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="pdf-chart-footer h-flex">
          <div className="pdf-chart-footer-left h-flex">
            <div><p>100%</p></div>
            <div><p>80%</p></div>
            <div><p>60%</p></div>
            <div><p>40%</p></div>
            <div><p>20%</p></div>
            {/* <div><p>0%</p></div> */}
          </div>
          <div className="pdf-chart-footer-middle"></div>
          <div className="pdf-chart-footer-right h-flex">
            {/* <div><p>0%</p></div> */}
            <div><p>20%</p></div>
            <div><p>40%</p></div>
            <div><p>60%</p></div>
            <div><p>80%</p></div>
            <div><p>100%</p></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">8</p>
      </div>
    );
  }
}

export default PDFRuleFour;
