import React from 'react';
import '../css/AuthorTwo.css';
import img from '../assets/scott.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';

const AuthorTwo = () => {
  return (
    <div className='authortwo-container'>

      {/* top block */}
      <div className='authortwo-block'>
        <h3 className='title'>
          Scott Snyder
        </h3>
        <div className='authortwo-top h-flex'>
          <div className='authortwo-top-left v-flex'>
            <p>Dr. Snyder is a recognized thought leader in technology and innovation. He has more than 25 years of experience in business leadership, strategy, innovation and technology management for Fortune 500 companies and startup ventures.  </p>
            <br />
            <p>Dr. Snyder is currently the President of Breakthru Advisors focused on helping enterprises leverage digital and other emerging technologies to accelerate innovation and new venture creation.  Dr. Snyder was recently the Global Head of Digital and Innovation at Heidrick Consulting where he is still a Senior Advisor.  Before Heidrick, he was the Chief Technology and Innovation Officer at Safeguard Scientifics which provides capital and relevant expertise to fuel the growth of technology-driven businesses in healthcare, financial services and digital media. Prior to Safeguard, Dr. Snyder was the Co-Founder, President and Chief Strategy Officer at Mobiquity (recently acquired by Hexaware), a leader in delivering innovative mobile and digital solutions for enterprises, where he continues to be Chairman of Mobiquity’s Advisory Board. Dr. Snyder has held executive positions with several Fortune 500 companies including GE, Martin Marietta, and Lockheed Martin, has been the CEO of a leading strategic planning firm, Decision Strategies International (now part of Heidrick & Struggles), and has also started business ventures in software including OmniChoice, a CRM/Analytics applications provider. He also serves on the Board of Directors for Fulton Financial Corporation (FFC).</p>
            <br />
            <p>Dr. Snyder has extensive experience educating and consulting to leading global organizations on digital transformation and innovation including GE, Lockheed Martin, US Navy, DuPont, Microsoft, Walmart, Sobeys, L’Oreal, Fidelity, John Hancock, Toyota, AT&T, Sprint, Verizon, Shell, AXA, J&J, Pfizer, Siemens, Intel, Novartis, CVS, United Technologies, Bayer, Nissan, Merck, SAP, Telstra, Reliance (India), Chubb, Medtronic, Aetna, Qualcomm, GSK, John Deere, KPMG, Marathon, Merck, and Wawa.
            </p>
            <br />
            <p>Dr. Snyder has authored a book entitled, “The New World of Wireless: How to Compete in the 4G Revolution”, and has been a long-time thought leader in the mobile and digital space. In addition, he is a chapter author in the books “The Network Challenge: Strategy, Profit, and Risk in the Interlinked World” and “Inside the Minds: Small Business Growth Strategies: Goals for Successful CEOs.” Dr. Snyder is a Senior Fellow in the Management Department at the Wharton School, an Adjunct Faculty Member in the School of Engineering and Applied Science at the University of Pennsylvania, and has lectured at MIT, Babson, Duke, Georgia Tech and INSEAD on Digital Innovation, Decision-making, Business and IT Strategy, Emerging Technologies, Product Design and Development, and Big Data/Analytics.  Dr. Snyder is a Digital Economy Project Fellow for the World Economic Forum and sits on the Penn Health Tech Advisory Board.  He also holds three patents for personalization and recommendation engines and has been quoted as a thought leader in numerous publications including CIO Magazine, WIRED, Forbes, Knowledge@Wharton, Los Angeles Times, The Wall Street Journal, the Philadelphia Inquirer and the Philadelphia Business Journal.
            </p>
            <br />
            <p>
              Dr. Snyder earned his B.S., M.S., and Ph.D. in Systems Engineering from University of Pennsylvania.
            </p>
          </div>

          <div className='authortwo-top-right v-flex'>

            <img src={img} alt="scott snyder" />
            <div className='social-icon h-flex'>
              <a href='https://www.linkedin.com/in/scottsnyder5g/' title=''><FontAwesomeIcon
                icon={['fab', 'linkedin']}
                size='3x'
                color='#007bb6'
              /></a>
              <a href='https://twitter.com/snyder4g' title=''><FontAwesomeIcon
                icon={['fab', 'twitter']}
                size='3x'
                color='#38A1F3'
              /></a>
              <a href='mailto:snyder4g@gmail.com'><FontAwesomeIcon
                icon='envelope'
                size='3x'
                color='#606D89'
              /></a>
            </div>
            <Button variant='outlined' color='primary' className='buttons-left'>
              <a href='mailto:snyder4g@gmail.com'>
                Speaking Inquiries for Scott
              </a>
            </Button>

          </div>
        </div>
      </div>

      <div className='video-block h-flex'>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/29tcO-EC63w?controls=0' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen title="2019 PACT phorum keynote"></iframe>
        </div>
        <div>
          <h3 className='title'>2019 PACT Phorum Keynote</h3>
          <br />
          <p>Scott Snyder gives a talk on how established companies can win the digital disruption game at Phorum Philly 2019.</p>
        </div>
      </div>

      <div class='video-block row-2 h-flex'>
        <div>
          <h3 className='title'>Scott Snyder Interview on CNBC Squawkbox</h3>
          <br />
          <p>Hear Scott Snyder discuss how established companies can be bigger market disruptors than start ups.</p>
        </div>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/7ir3WaOklfs?controls=0' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen title="Scott snyder interview on CNBC squawkbox"></iframe>
        </div>

      </div>

      <div class='video-block row-3 h-flex'>
        <div>
          <iframe width='770' height='435' frameborder='0' src='https://finance.yahoo.com/video/companies-turning-tables-digital-disruptors-214922091.html?format=embed' allowfullscreen='true' mozallowfullscreen='true' webkitallowfullscreen='true' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' title="scott snyder interview on yahoo finance tv"></iframe>
        </div>
        <div>
          <h3 className='title'>Scott Snyder Interview on Yahoo Finance TV</h3>
          <br />
          <p>Hear Scott Snyder highlight some of the key ways in which large companies can leverage their position in the digital world.</p>
        </div>

      </div>

      <div class='video-block row-4 h-flex'>
        <div>
          <h3 className='title'>Digital Disruption, The New Normal</h3>
          <br />
          <p>Hear Scott Snyder talks about digital disruption and the need for companies to reinvent themselves.</p>
        </div>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/HW19PrkQROw?controls=0' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen title="digital disruption, the new normal"></iframe>
        </div>

      </div>

      <div class='video-block row-5 h-flex'>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/51IRQC3gTZc?controls=0' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen title="talent and purpose as fuel for reinvention"></iframe>
        </div>
        <div>
          <h3 className='title'>Talent and Purpose as fuel for reinvention</h3>
          <br />
          <p>Hear Scott Snyder talks about two of the rule in Goliath's revenge focused on talent and purpose.</p>
        </div>

      </div>

      <div class='video-block row-6 h-flex'>
        <div>
          <h3 className='title'>New Rules for being a disruptor</h3>
          <br />
          <p>Hear Scott Snyder talk about the first four rules for being a disruptor in Goliath's Revenge.</p>
        </div>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/NCZvuW6-wlE?controls=0' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen title="new rules for being a disruptor"></iframe>
        </div>

      </div>

      <div class='video-block row-7 h-flex'>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/U9YEchw_4Ys?controls=0' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen title="digital acceleration - the human side"></iframe>
        </div>
        <div>
          <h3 className='title'>Digital Acceleration - The Human Side</h3>
          <br />
          <p>Hear Scott Snyder talks about the human challenges of digital acceleration.</p>
        </div>

      </div>

      <div class='video-block row-8 h-flex'>
        <div>
          <h3 className='title'>How the App Store recasts the Consumer as Innovator</h3>
          <br />
          <p>Hear Wharton Senior Fellow Scott Snyder talk about how end-users are driving the innovation in the digital era and companies need to get on board.</p>
        </div>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/K6W_uHqcGKk?rel=0&amp;controls=0&amp;showinfo=0' frameborder='0' allow='autoplay; encrypted-media' allowfullscreen title="how the app store recasts the consumer as innovator"></iframe>
        </div>
      </div>

      <div class='video-block row-9 h-flex'>
        <div>
          <iframe width='770' height='435' src='https://www.youtube.com/embed/k59aDbovzf0?controls=0&amp;start=176' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen title="smartphones as personal assistants"></iframe>
        </div>
        <div>
          <h3 className='title'>Smartphones as Personal Assistants</h3>
          <br />
          <p>Hear Scott Snyder talk about digital disruption and empowered end-users impacting industries like Retail and Healthcare at the Advancing the Vision Conference hosted by Corning at Stanford University.</p>
        </div>
      </div>

    </div>

  );
}

export default AuthorTwo;
