import React from 'react';
import { Router, NavLink } from 'react-router-dom';
import history from '../history';

const RuleSwitch = (props) => {

  const currentRule = window.location.pathname.split('/')[2]
  const companyRuleURL = `/selfassessment/${currentRule}/company`
  const careerRuleURL = `/selfassessment/${currentRule}/career`

  return (
    <div className="rule-switch">
      <Router history={history}>
        <div className="h-flex">
          <NavLink activeClassName="switch-active" className="switch" to={companyRuleURL} >
            <div className="v-flex" onClick={props.updateDataBase} >
              <div>Complete COMPANY </div>
              <div>Self-Assessment Grid</div>
            </div>
          </NavLink>

          <NavLink activeClassName="switch-active" className="switch" to={careerRuleURL} >
            <div className="v-flex" onClick={props.updateDataBase} >
              <div>Complete CAREER </div>
              <div>Self-Assessment Grid</div>
            </div>
          </NavLink>
        </div>
      </Router>
    </div>
  );
}

export default RuleSwitch;
