import React from 'react';
import '../css/Dashboard.css';
import RuleOneProgress from '../rules/RuleOneProgress';
import RuleTwoProgress from '../rules/RuleTwoProgress';
import RuleThreeProgress from '../rules/RuleThreeProgress';
import RuleFourProgress from '../rules/RuleFourProgress';
import RuleFiveProgress from '../rules/RuleFiveProgress';
import RuleSixProgress from '../rules/RuleSixProgress';

const DashBoardTable = ({ scores, balls, result, processPercent }) => {

  return (
    <div>

      <div className="dash-rule-list v-flex">
        <div className="dash-rule-list-1 h-flex">
          <RuleOneProgress
            companyScore={scores.company_1}
            careerScore={scores.career_1}
            companyBall={balls.company_1}
            careerBall={balls.career_1}
            company={result.company_1}
            career={result.career_1}
            processPercent={processPercent}
          />
          <RuleTwoProgress
            companyScore={scores.company_2}
            careerScore={scores.career_2}
            companyBall={balls.company_2}
            careerBall={balls.career_2}
            company={result.company_2}
            career={result.career_2}
            processPercent={processPercent}
          />
        </div>

        <div className="dash-rule-list-2 h-flex">
          <RuleThreeProgress
            companyScore={scores.company_3}
            careerScore={scores.career_3}
            companyBall={balls.company_3}
            careerBall={balls.career_3}
            company={result.company_3}
            career={result.career_3}
            processPercent={processPercent}
          />
          <RuleFourProgress
            companyScore={scores.company_4}
            careerScore={scores.career_4}
            companyBall={balls.company_4}
            careerBall={balls.career_4}
            company={result.company_4}
            career={result.career_4}
            processPercent={processPercent}
          />
        </div>

        <div className="dash-rule-list-3 h-flex">
          <RuleFiveProgress
            companyScore={scores.company_5}
            careerScore={scores.career_5}
            companyBall={balls.company_5}
            careerBall={balls.career_5}
            company={result.company_5}
            career={result.career_5}
            processPercent={processPercent}
          />
          <RuleSixProgress
            companyScore={scores.company_6}
            careerScore={scores.career_6}
            companyBall={balls.company_6}
            careerBall={balls.career_6}
            company={result.company_6}
            career={result.career_6}
            processPercent={processPercent}
          />
        </div>
      </div>
    </div>
  )
}

export default DashBoardTable;
