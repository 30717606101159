import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleOneCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "company",
      rule: 1,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.company.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.company,
      selected: this.props.selected,
    })
    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule One</p>
            <p>Company Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>10X customer value</p>
            <div className="rule-select" data-row="1" data-col="1">Outcome value not yet validated with customers</div>
            <div className="rule-select" data-row="1" data-col="2">Traditional customer value model with a 3-5 year payback</div>
            <div className="rule-select" data-row="1" data-col="3">Differentiated value on two of: higher revenue, lower cost, reduced risk</div>
            <div className="rule-select" data-row="1" data-col="4">Differentiated value on three of: higher revenue, lower cost, reduced risk</div>
            <div className="rule-select" data-row="1" data-col="5">Customer-validated ROI model showing 10X cash-on-cash returns</div>
          </div>
          <div className="h-flex rule-block">
            <p>Incumbent's advantage</p>
            <div className="rule-select" data-row="2" data-col="1">Outcome does not everage any existing crown jewels</div>
            <div className="rule-select" data-row="2" data-col="2">Outcome builds on crown jewels in 1-2 categories</div>
            <div className="rule-select" data-row="2" data-col="3">Outcome builds on crown jewels in 3-4 categories</div>
            <div className="rule-select" data-row="2" data-col="4">Outcome builds on crown jewels in 5-6 categories</div>
            <div className="rule-select" data-row="2" data-col="5">Outcome builds on crown jewels in all 7 categories</div>
          </div>
          <div className="h-flex rule-block">
            <p>Buyer persona outcomes</p>
            <div className="rule-select" data-row="3" data-col="1">Overall step-change customer outcome not yet broken down by buyer persona</div>
            <div className="rule-select" data-row="3" data-col="2">Outcomes developed for only a subset of the 4 step-specific buyer personas</div>
            <div className="rule-select" data-row="3" data-col="3">All 4 step-specific customer outcomes developed but not yet validated</div>
            <div className="rule-select" data-row="3" data-col="4">Step-specific outcomes validated with &#60; 10 customers in each of 4 personas</div>
            <div className="rule-select" data-row="3" data-col="5">Step-specific outcomes validated with 10+ customers in each of 4 personas</div>
          </div>
          <div className="h-flex rule-block">
            <p>Whole offer clarity</p>
            <div className="rule-select" data-row="4" data-col="1">Whole offers specified by step buy without detailed capability definitions</div>
            <div className="rule-select" data-row="4" data-col="2">Capability definitions in place for 2+ whole offer on each of steps 1 and 2</div>
            <div className="rule-select" data-row="4" data-col="3">Capability definitions in place for 2+ whole offer on each of the 4 steps</div>
            <div className="rule-select" data-row="4" data-col="4">Build vs. Partner decisions made for capabilities in step 1 and 2 while offers</div>
            <div className="rule-select" data-row="4" data-col="5">Build vs. Partner sourcing clear for every whole offer on every step</div>
          </div>
          <div className="h-flex rule-block">
            <p>Whole offer ecosystem</p>
            <div className="rule-select" data-row="5" data-col="1">Whole offers capabilities partners not yet sourced and signed</div>
            <div className="rule-select" data-row="5" data-col="2">At least 1 validated partner sourced and signed for Step 1 and 2 whole offers</div>
            <div className="rule-select" data-row="5" data-col="3">Multiple validated partners sourced and signed for Step 1 and 2 whole offers</div>
            <div className="rule-select" data-row="5" data-col="4">At least 1 validated partner sourced, signed and trained for all whole offers</div>
            <div className="rule-select" data-row="5" data-col="5">Multiple validated partners sourced, signed, and trained for all whole offers</div>
          </div>
          <div className="h-flex rule-block">
            <p>Time to market</p>
            <div className="rule-select" data-row="6" data-col="1">1+ whole offer(s) can be in market within 12 months on each of steps 1 and 2</div>
            <div className="rule-select" data-row="6" data-col="2">1+ whole offer(s) can be in market within 6 months on each of steps 1 and 2</div>
            <div className="rule-select" data-row="6" data-col="3">1+ whole offer(s) already in market on stairway step 1</div>
            <div className="rule-select" data-row="6" data-col="4">1+ whole offer(s) already in market on each of steps 1 and 2</div>
            <div className="rule-select" data-row="6" data-col="5">1+ whole offer(s) already in market on each of steps 1, 2, 3</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleOneCompany;
