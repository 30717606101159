import React from 'react';

// import { Link, Router } from 'react-router-dom'

const PDFCover = (props) => {
  return (
    <div className="pdf-page v-flex">
      <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
      <h1 className="pdf-title">Table of Contents</h1>
      <div>
        <h3 className="pdf-title-cover">Overall Readiness Summaries</h3>
        <br />
        <div className="pdf-table-index h-flex">
          <p>Company Readiness Summary</p>
          <p>3</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Career Readiness Summary</p>
          <p>4</p>
        </div>
      </div>
      <br />
      <div>
        <h3 className="pdf-title-cover">Six New Rules Readiness Summaries</h3>
        <br />
        <div className="pdf-table-index h-flex">
          <p>Rule #1: Step-Change Customer Outcomes Readiness Summary</p>
          <p>5</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #2: Pursue Big I and Little I Readiness Summary</p>
          <p>6</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #3: Use Your Data as Currency Readiness Summary</p>
          <p>7</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #4: Accelerate Through Innovation Networks Readiness Summary</p>
          <p>8</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #5: Value Talent Over Technology Readiness Summary</p>
          <p>9</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #6: Reframe Your Purpose Readiness Summary</p>
          <p>10</p>
        </div>
      </div>
      <br />
      <div>
        <h3 className="pdf-title-cover">Self-Assessment Grids</h3>
        <br />
        <br />
        <div className="pdf-table-index h-flex">
          <p>Rule #1: Step-Change Customer Outcomes Company Self-Assessment Grid</p>
          <p>11</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #1: Step-Change Customer Outcomes Career Self-Assessment Grid</p>
          <p>12</p>
        </div>
        <br />
        <br />
        <div className="pdf-table-index h-flex">
          <p>Rule #2: Pursue Big I and Little I Company Self-Assessment Grid</p>
          <p>13</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #2: Pursue Big I and Little I Career Self-Assessment Grid</p>
          <p>14</p>
        </div>
        <br />
        <br />
        <div className="pdf-table-index h-flex">
          <p>Rule #3: Use Your Data as Currency Company Self-Assessment Grid</p>
          <p>15</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #3: Use Your Data as Currency Career Self-Assessment Grid</p>
          <p>16</p>
        </div>
        <br />
        <br />
        <div className="pdf-table-index h-flex">
          <p>Rule #4: Accelerate Through Innovation Networks Company Self-Assessment Grid</p>
          <p>17</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #4: Accelerate Through Innovation Networks Career Self-Assessment Grid</p>
          <p>18</p>
        </div>
        <br />
        <br />
        <div className="pdf-table-index h-flex">
          <p>Rule #5: Value Talent Over Technology Company Self-Assessment Grid</p>
          <p>19</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #5: Value Talent Over Technology Career Self-Assessment Grid</p>
          <p>20</p>
        </div>
        <br />
        <br />
        <div className="pdf-table-index h-flex">
          <p>Rule #6: Reframe Your Purpose Company Self-Assessment Grid</p>
          <p>21</p>
        </div>
        <div className="pdf-table-index h-flex">
          <p>Rule #6: Reframe Your Purpose Career Self-Assessment Grid</p>
          <p>22</p>
        </div>
      </div>
      <br />
      <br />
      <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
      <p className="pdf-footer-number">2</p>
    </div>
  );
}

export default PDFCover;
