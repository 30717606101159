import React, { Component } from 'react';

class PDFRuleThreeCareer extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.careerArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-career-3-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #3: Use Your Data As Currency Career Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Three</p>
            <p>Career</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Have quality big data assets</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-1"><p>Have not been part of my company's push into big data and analytics</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-1"><p>Appreciate the value of high-quality data but do not have skills in this area</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-1"><p>Invested personal time to learn data science basics but on-the-job experience is limited</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-1"><p>Deep experience in making data valuable for data science and machine learning</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-3-1"><p>Go-to leader for assessing the quality and business potential of new data sets</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Manageable data liabilities</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-2"><p>Not involved with commitments around data rights and obligations</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-2"><p>Have negotiated commercial contracts that include explicit data rights</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-2"><p>Up-to-speed on the trend toward more complex data governance laws</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-2"><p>Substantial expertise in implementing data privacy and security safeguards</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-3-2"><p>Recognized expert on reginal data sovereignty and governance</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Place value on data optionality</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-3"><p>Do not see any intrinsic value in data - it is just a means to an end</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-3"><p>I am not able to place a value on the direct, near-term impact of data</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-3"><p>Willing to include future data value as we make prioritization decisions</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-3"><p>Attribute explicit value to second-order effects of acquiring new data assets</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-3-3"><p>I built our company's model for valuing second- and third-order effects</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Board customer segment focus</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-4"><p>No material experience yet delivering on digital use cases</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-4"><p>Direct experience in use cases within at least 1 digital customer segment</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-4"><p>Direct experience in use cases within at least 2 digital customer segments</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-4"><p>Direct experience in use cases within at least 3 digital customer segments</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-3-4"><p>Direct experience in use cases for all 4 of the digital customer segments</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Building data science team</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-5"><p>Few data science skills and minimal big data analytics experience yet</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-5"><p>Built data science skills through online and company training programs</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-5"><p>Demonstrated success across multiple data science projects</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-5"><p>Go-to leader within multiple stages of the analytics value chain from data and insights</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-3-5"><p>Expert in all facets of turning big data into actionable, high-value business insights</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Mastering machine learning</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-6"><p>No experience or skill yet in machine learning tools and projects</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-6"><p>Building my skills as part of my first machine learning project</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-6"><p>Experienced with multiple successful machine learning efforts</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-3-6"><p>Viewed as the go-to expert to deliver continuous machine learning</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-3-6"><p>Teach machine learning at our local college in my spare time</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">16</p>
      </div>
    );
  }
}

export default PDFRuleThreeCareer;
