import React from 'react';
import { NavLink, Router } from 'react-router-dom'
import history from '../history';
import '../css/Header.css';

const Header = () => {
  return (
    <div className="header">
      <Router history={history}>
        <ul className="nav-block h-flex">
          <li><NavLink to="/"><p>Home</p></NavLink></li>
          <li><NavLink to="/book"><p>About the Book</p></NavLink></li>
          <li><NavLink to="/toddhewlin"><p>About Todd Hewlin</p></NavLink></li>
          <li><NavLink to="/scottsnyder"><p>About Scott Snyder</p></NavLink></li>
          <li><NavLink to="/selfassessment"><p>Complete the Self-Assessment</p></NavLink></li>
        </ul>
      </Router>
    </div>
  );
}

export default Header;
