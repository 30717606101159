import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from '../history';
import RuleFiveCompany from './RuleFiveCompany';
import RuleFiveCareer from './RuleFiveCareer';
import RuleTableHeading from '../components/RuleTableHeading';
import RuleFiveProgress from './RuleFiveProgress';

const RuleFive = ({ company, career, companySelected, careerSelected, email, getResult, updateProps, companyScore, careerScore, companyBall, careerBall, processPercent }) => {
  return (
    <div>
      <RuleTableHeading />
      <div className="center">
        <RuleFiveProgress
          companyScore={companyScore}
          careerScore={careerScore}
          companyBall={companyBall}
          careerBall={careerBall}
          company={company}
          career={career}
          processPercent={processPercent}
        />
      </div>

      <Router history={history}>
        <div className="v-flex rule-container">
          <Route
            path="/selfassessment/rulefive/company"
            render={props => <RuleFiveCompany {...props}
              company={company}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={companySelected}
            />}
          />
          <Route
            path="/selfassessment/rulefive/career"
            render={props => <RuleFiveCareer {...props}
              career={career}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={careerSelected}
            />}
          />
        </div>
      </Router>
    </div>
  );
}

export default RuleFive;
