import React, { Component } from 'react';

class PDFRuleSixCareer extends Component {

  componentDidMount() {
    this.processData();
  }

  processData() {
    const selection = this.props.careerArr.split('')
    for (let i = 0; i < selection.length; i++) {
      const htmlColl = document.getElementsByClassName(`pdf-table-fill-career-6-${i + 1}`)
      const cur = selection[i];
      htmlColl[cur - 1].classList.add('pdf-table-filled-blue')
    }
  }

  render() {
    return (
      <div className="pdf-page v-flex">
        <p className="pdf-header">Goliath's Revenge Self-Assessment Results</p>
        <h2 className="pdf-title">Rule #6: Reframe Your Purpose Career Self-Assessment Grid</h2>
        <div className="h-flex pdf-cate">
          <div className="border-to-right v-flex">
            <p>Rule Six</p>
            <p>Career</p>
          </div>
          <div>
            <p>0-20% Minimal Capability</p>
          </div>
          <div>
            <p>20-40% Limited Capability</p>
          </div>
          <div>
            <p>40-60% Moderate Capability</p>
          </div>
          <div>
            <p>60-80% Advanced Capability</p>
          </div>
          <div>
            <p>80-100% World Class Capability</p>
          </div>
        </div>
        <div className="v-flex pdf-table">
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Raise your sights</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-1"><p>My team is just "heads down" trying to deliver on our performance metrics</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-1"><p>My team is aligned to our company's purpose but does not have its own</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-1"><p>My team has a draft statement of purpose that is still being refined</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-1"><p>Mission / vision for my team is compelling but not sure if it aligns to our company overall</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-6-1"><p>Mission / vision for my team is compelling and aligned to that of our company</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Answer the "Five Whys"</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-2"><p>Our company is doing this "Five Whys" effort but don't know how that effects me</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-2"><p>My career path is built around helping our company deliver the 2nd "why"</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-2"><p>My career path is built around helping our company deliver the 3rd "why"</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-2"><p>My career path is built around helping our company deliver the 4th "why"</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-6-2"><p>My career path is built around helping our company deliver the 5th "why"</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Embrace smart cannibalization</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-3"><p>If our company has a two-speed organization model, I don't know about it</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-3"><p>I have had deep experience in speed one team but not in speed two</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-3"><p>I have had deep experience in speed two teams but not in speed one</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-3"><p>Have worked in both speed one and two teams but am not equally proficient yet</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-6-3"><p>I am ambidextrous; I have excelled in both speed one and speed two teams</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Engage the next generation</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-4"><p>Not sure what all the fuss about with these social and environmental issues</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-4"><p>Just starting to appreciate that Digital Natives are a breath of fresh air</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-4"><p>I have adjusted my working style over time to better relate t Digital Natives</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-4"><p>I am an active mentor to Digital Natives within and beyond my current team</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-6-4"><p>I built our triple bottom line focus into how my team runs day-to-day</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Align top-down</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-5"><p>Don't think alignment is worth the effort; why can't everyone just do their jobs?</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-5"><p>My team has started talking about alignment but not sure we can get there</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-5"><p>I drive alignment within my team but not out to peers or up the organization chart</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-5"><p>I drive alignment with my team and peers but have limited influence upward</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-6-5"><p>I personally drive alignment within the 360 degree sphere around my role</p></div></div>
          </div>
          <div className="h-flex pdf-block">
            <p className="pdf-table-border">Lead by example</p>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-6"><p>These personal statements of purpose are just a waste of time</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-6"><p>I am ready to commit time and energy to figuring out what my personal purpose is</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-6"><p>Have ideas about my personal purpose but have not written it down in a formal way</p></div></div>
            <div className="pdf-table-border"><div className="pdf-table-fill-career-6-6"><p>My personal purpose is compelling but not sure if it aligns to our company very well</p></div></div>
            <div className="pdf-table-border-last"><div className="pdf-table-fill-career-6-6"><p>My personal purpose is compelling and aligned with our company's purpose</p></div></div>
          </div>
        </div>
        <p className="pdf-footer">Copyright By Todd Hewlin and Scott Snyder, 2018</p>
        <p className="pdf-footer-number">22</p>
      </div>
    );
  }
}

export default PDFRuleSixCareer;
