import React, { Component } from 'react';
import RuleNavBottom from './RuleNavBottom';
import RuleSwitch from './RuleSwitch';
import baseURL from '../services/api_url';

class RuleTwoCareer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      type: "career",
      rule: 2,
      email: this.props.email
    };
    this.loadCurrentSelect = this.loadCurrentSelect.bind(this);
    this.addOnClickEvent = this.addOnClickEvent.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.updateDataBase = this.updateDataBase.bind(this);
  }

  componentDidMount() {
    this.loadCurrentSelect();
    this.addOnClickEvent();
  }

  loadCurrentSelect() {
    const result = this.props.career.split('');
    const selected = this.props.selected.split('');
    this.setState({
      result: this.props.career,
      selected: this.props.selected,
    })

    const htmlColl = document.getElementsByClassName('rule-block')
    for (let i = 0; i < 6; i ++) {
      if (selected[i] === 't') {
        htmlColl[i].childNodes[result[i]].classList.add('selection');
      }
    }
  }

  updateDataBase() {
    const data = this.state;
    fetch(`${baseURL}/api/result`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        this.props.getResult();
      })
  }

  updateSelection(e) {
    const newresult = this.state.result.split('');
    const newSelected = this.state.selected.split('');
    let row = e.target.dataset.row;
    let col = e.target.dataset.col
    newresult[row-1] = col;
    newSelected[row-1] = 't';

    const columnName = `${this.state.type}_${this.state.rule}`;
    const columnValue = this.state.result;

    this.props.updateProps(columnName, columnValue);

    this.setState({
      result: newresult.join(''),
      selected: newSelected.join('')
    })
  }

  checkSelect(e) {
    if (e.target.classList[1] !== 'selection') {
      const siblingNodes = e.target.parentNode.childNodes
      for (let i = 0; i < siblingNodes.length; i ++) {
        if (siblingNodes[i].classList[1] === 'selection') {
          siblingNodes[i].classList.remove('selection');
        }
      }
    }

    e.target.classList.add('selection');

    this.updateSelection(e);
  }

  addOnClickEvent() {
    const htmlColl = document.getElementsByClassName('rule-select')
    for (let i = 0; i < htmlColl.length; i ++) {
      htmlColl[i].addEventListener('click', this.checkSelect);
    }
  }

  render() {
    return (
      <div className="center">
        <RuleSwitch updateDataBase={this.updateDataBase} />
        <div className="h-flex rule-cate">
          <div className="border-to-right v-flex">
            <p>Rule Two</p>
            <p>Career Grid</p>
          </div>
          <div className="v-flex">
            <p>0-20%</p>
            <p>Minimal Capability</p>
          </div>
          <div className="v-flex">
            <p>20-40%</p>
            <p>Limited Capability</p>
          </div>
          <div className="v-flex">
            <p>40-60%</p>
            <p> Moderate Capability</p>
          </div>
          <div className="v-flex">
            <p>60-80%</p>
            <p>Advanced Capability</p>
          </div>
          <div className="v-flex">
            <p>80-100%</p>
            <p>World Class Capability</p>
          </div>
        </div>
        <div className="v-flex rule-table">
          <div className="h-flex rule-block">
            <p>Both Big I and Little I</p>
            <div className="rule-select" data-row="1" data-col="1">Have not been part of any innovation initiative</div>
            <div className="rule-select" data-row="1" data-col="2">Was on a continuous improvement team a long time ago</div>
            <div className="rule-select" data-row="1" data-col="3">Recognized as a valued Little I initiative team member</div>
            <div className="rule-select" data-row="1" data-col="4">Have been a key member of both Little I and Big I teams over time</div>
            <div className="rule-select" data-row="1" data-col="5">Have led multiple Little I and Big I initiatives to achieve their goals</div>
          </div>
          <div className="h-flex rule-block">
            <p>Innovation culture</p>
            <div className="rule-select" data-row="2" data-col="1">If we have an idea generation program, I am not familiar with it</div>
            <div className="rule-select" data-row="2" data-col="2">I know the email alias to send suggestions to but have never done so</div>
            <div className="rule-select" data-row="2" data-col="3">Am a regular contributor of improvement and new business ideas</div>
            <div className="rule-select" data-row="2" data-col="4">Have received executive recognition as source of successful innovative ideas</div>
            <div className="rule-select" data-row="2" data-col="5">Have been asked to lead part of our company wide innovation program</div>
          </div>
          <div className="h-flex rule-block">
            <p>Fast action on Little I</p>
            <div className="rule-select" data-row="3" data-col="1">I need to focus on delivering the core commitments of my current role</div>
            <div className="rule-select" data-row="3" data-col="2">I am willing to put in extra effort to be part of rapid improvement teams</div>
            <div className="rule-select" data-row="3" data-col="3">I am the "go to" person in my group for teams that need to get things done</div>
            <div className="rule-select" data-row="3" data-col="4">I have a network across the company that can help push through innovations</div>
            <div className="rule-select" data-row="3" data-col="5">I am the benchmark for how to execute innovation with limited resources</div>
          </div>
          <div className="h-flex rule-block">
            <p>Power of And</p>
            <div className="rule-select" data-row="4" data-col="1">I focus on one thing at a time and often lose track of other priorities</div>
            <div className="rule-select" data-row="4" data-col="2">I am working hard on my time management skills to be able to do more in parallel</div>
            <div className="rule-select" data-row="4" data-col="3">I can handle two initiatives in parallel but adding a third one is too much</div>
            <div className="rule-select" data-row="4" data-col="4">I am highly productive acting on multiple competing priorities</div>
            <div className="rule-select" data-row="4" data-col="5">Have a high tolerance for ambiguity; willing to act in the fact of limited information</div>
          </div>
          <div className="h-flex rule-block">
            <p>Venture Investment Board</p>
            <div className="rule-select" data-row="5" data-col="1">I did not realize that we had a Venture Investment Board</div>
            <div className="rule-select" data-row="5" data-col="2">Was part of a team that made an unsuccessful proposal to our VIB</div>
            <div className="rule-select" data-row="5" data-col="3">Was part of a team that gained "GO" approval from our VIB</div>
            <div className="rule-select" data-row="5" data-col="4">Had led multiple successful teams that gained "GO" approval from our VIB</div>
            <div className="rule-select" data-row="5" data-col="5">Have been asked to be a board-observer on our VIB to represent my group</div>
          </div>
          <div className="h-flex rule-block">
            <p>Big I Relay Race</p>
            <div className="rule-select" data-row="6" data-col="1">Have never expressed interest in being part of a Big I initiative team</div>
            <div className="rule-select" data-row="6" data-col="2">Am trained in our Big I methodology but have not yet been on a Big I team</div>
            <div className="rule-select" data-row="6" data-col="3">Have been a core member of at least one successful Big I team</div>
            <div className="rule-select" data-row="6" data-col="4">Have been a team leader on at least one successful Big I team</div>
            <div className="rule-select" data-row="6" data-col="5">Have acted as a faculty member within our company's Big I training program</div>
          </div>
        </div>
        <br />
        <div className="center">
          <RuleNavBottom updateDataBase={this.updateDataBase} />
        </div>
      </div>
    )
  }
}

export default RuleTwoCareer;
