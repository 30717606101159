import React from 'react';
import '../css/Dashboard.css';
import slingshot from '../assets/slingshot.png';

const RuleTableHeading = () => {
  return (
    <div className="dashboard v-flex">
      <div className="dashboard-heading h-flex">
        <div className="dashboard-heading-left">
          <img src={slingshot} alt="slingshot" />
        </div>
        <div className="dashboard-heading-right v-flex">
          <p>To complete the self-assessments for this rule, please click on either “Complete Company Self-Assessment Grid” or “Complete Career Self-Assessment Grid” below. You can complete the company and career grids in either order. Within the grids, just click on the cell in each row that best reflects your current company or career capability level. Make sure that you have read the chapter in Goliath’s Revenge that focuses on this new rule as the prompts within the cells below refer to content from the book.
          </p>
        </div>
      </div>
    </div>
  );
}

export default RuleTableHeading;
