import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from '../history';
import RuleTwoCompany from './RuleTwoCompany';
import RuleTwoCareer from './RuleTwoCareer';
import RuleTableHeading from '../components/RuleTableHeading';
import RuleTwoProgress from './RuleTwoProgress';

const RuleTwo = ({ company, career, companySelected, careerSelected,  email, getResult, updateProps, companyScore, careerScore, companyBall, careerBall, processPercent }) => {
  return (
    <div>
      <RuleTableHeading />
      <div className="center">
        <RuleTwoProgress
          companyScore={companyScore}
          careerScore={careerScore}
          companyBall={companyBall}
          careerBall={careerBall}
          company={company}
          career={career}
          processPercent={processPercent}
        />
      </div>

      <Router history={history}>
        <div className="v-flex rule-container">
          <Route
            path="/selfassessment/ruletwo/company"
            render={props => <RuleTwoCompany {...props}
              company={company}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={companySelected}
            />}
          />
          <Route
            path="/selfassessment/ruletwo/career"
            render={props => <RuleTwoCareer {...props}
              career={career}
              email={email}
              getResult={getResult}
              updateProps={updateProps}
              selected={careerSelected}
            />}
          />
        </div>
      </Router>
    </div>
  );
}

export default RuleTwo;
